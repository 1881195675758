:root {
  /* Colors */
  --color-bg1: rgb(8, 10, 15);
  --color-bg2: rgb(0, 17, 32);
  --color1: 18, 113, 255;
  --color2: 107, 74, 255;
  --color3: 100, 100, 255;
  --color4: 50, 160, 220;
  --color5: 80, 47, 122;
  --color-interactive: 140, 100, 255;

  /* Sizes and Effects */
  --circle-size: 80%;
  --blending: hard-light;
  --grid-gap: 1rem;

  /* Colors */
  --primary-color: #4a90e2;
  --secondary-color: #2c3e50;
  --text-color: #333;
  --background-color: #fff;

  /* Fonts */
  --font-opensans: 'Open Sans', Arial, sans-serif;
  --font-raleway: 'Raleway', sans-serif;
  --font-roboto: 'Roboto', sans-serif;

  /* Layout */
  --card-padding: 48px;
  --logo-width: 220px;
}
