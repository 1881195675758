/* Font Imports */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400&display=swap');

/* Base Typography */
body {
  font-family: var(--font-roboto);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-opensans);
}

/* Text Utilities */
.text-center {
  text-align: center;
}

.text-light {
  font-weight: 300;
}
