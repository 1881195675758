/* Base Reset */
* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  color: #ffffff;
  background: transparent;
  border: none;
}

/* Document Root Reset */
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: #121212;
  background-color: #121212;
  color: #f0f0f0;
  font-family: var(--font-opensans), sans-serif;
}

/* Base Element Reset */
body {
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* Link Reset */
a {
  color: rgba(255, 255, 255, 0.5);
  word-break: break-word;
  text-decoration: none;
  color: inherit;
}

/* Image Reset */
img {
  outline: none;
  -webkit-user-drag: none;
}

/* Paragraph Reset */
p {
  margin: 0;
  padding: 0;
}
