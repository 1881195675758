/* Legal Documents Container */
.legal-logo-container {
  position: fixed;
  display: block;
  top: 12px;
  left: 28px;
  opacity: 0;
  transition: opacity 200ms ease-in 0ms;
  z-index: 991120;
}

.legal-logo-container img {
  width: 175px;
}

body.fs-legal-show .legal-logo-container {
  opacity: 1;
  transition: all 800ms ease-out 1200ms;
}

.legal-docs-title {
  display: none !important;
}

body.fs-legal-show .footer .logo-container img {
  opacity: 0;
  transition: all 800ms ease-out;
}
