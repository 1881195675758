/* 
    Background Stars
    Author: John Garcia https://codepen.io/johnbgarcia
    Link: https://codepen.io/johnbgarcia/pen/qqdgGp
    Modified for Firesite.ai 01/04/2025 
*/

/* Shooting Stars */
.shooting {
  z-index: 10;
  width: 5px;
  height: 85px;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  position: absolute;
  top: -15vh;
  right: 75vw;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white);
  opacity: 0;
  animation: none;
}

.shooting.active {
  opacity: 1;
  animation: animShootingStar 4.25s linear;
}

.shooting.small {
  width: 3px;
  height: 60px;
  top: -10vh;
  right: 85vw;
}

.shooting.small.active {
  animation: animShootingStar 3s linear;
}

.shooting.far {
  top: -25vh;
  right: 38vw;
  scale: 0.5;
}

.shooting.far.active {
  animation: animShootingStar 3.25s linear;
}

.shooting.fast {
  top: -15vh;
  right: 25vw;
  scale: 0.75;
  opacity: 0.45;
}

.shooting.fast.active {
  animation: animShootingStar 1.5s linear;
}

@keyframes animShootingStar {
  from {
    transform: translateY(0) translateX(0) rotate(315deg);
    opacity: 1;
    height: 5px;
  }
  25% {
    height: 400px;
    opacity: 1;
  }
  50% {
    height: 800px;
    opacity: 1;
  }
  to {
    transform: translateY(2560px) translateX(2560px) rotate(315deg);
    opacity: 0;
    height: 800px;
  }
}

/* Background Stars */
.stars0 {
  z-index: 10;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
  box-shadow:
    975px 1151px #fff,
    1361px 2310px #fff,
    853px 1369px #fff,
    1826px 148px #fff,
    2262px 894px #fff,
    1461px 386px #fff,
    1152px 600px #fff,
    1990px 1884px #fff,
    1221px 1462px #fff,
    41px 1942px #fff,
    291px 2171px #fff,
    1519px 124px #fff,
    1366px 2497px #fff,
    102px 1946px #fff,
    1137px 1065px #fff,
    1477px 623px #fff,
    872px 31px #fff,
    2548px 1616px #fff,
    1592px 1682px #fff,
    2176px 1553px #fff,
    332px 539px #fff,
    1065px 697px #fff,
    2038px 1780px #fff,
    2209px 2550px #fff,
    316px 1532px #fff,
    1182px 2533px #fff,
    253px 149px #fff,
    878px 2064px #fff,
    1626px 69px #fff,
    2531px 2185px #fff,
    168px 799px #fff,
    362px 1867px #fff,
    1825px 2150px #fff,
    2244px 689px #fff,
    430px 936px #fff,
    1487px 2329px #fff,
    474px 645px #fff,
    2246px 75px #fff,
    367px 1082px #fff,
    2268px 1134px #fff,
    34px 1001px #fff,
    2291px 2305px #fff,
    837px 1794px #fff,
    852px 1753px #fff,
    1675px 1877px #fff,
    1459px 1140px #fff,
    1276px 573px #fff,
    910px 2129px #fff,
    1117px 1818px #fff,
    146px 620px #fff,
    1375px 1558px #fff,
    558px 2155px #fff,
    2119px 529px #fff,
    1670px 817px #fff,
    733px 758px #fff,
    804px 1784px #fff,
    633px 964px #fff,
    2335px 404px #fff,
    783px 1802px #fff,
    1066px 1489px #fff,
    1590px 803px #fff,
    852px 40px #fff,
    1493px 2279px #fff,
    379px 407px #fff,
    1287px 907px #fff,
    2503px 2365px #fff,
    2530px 535px #fff,
    1820px 838px #fff,
    2104px 902px #fff,
    2212px 25px #fff,
    2110px 2555px #fff,
    178px 1632px #fff,
    2320px 571px #fff,
    1798px 1629px #fff,
    601px 2362px #fff,
    1368px 432px #fff,
    1216px 1769px #fff,
    802px 1059px #fff,
    1918px 189px #fff,
    1071px 1096px #fff,
    428px 2008px #fff,
    2542px 1716px #fff,
    808px 2025px #fff,
    1586px 155px #fff,
    2182px 1805px #fff,
    1045px 1094px #fff,
    440px 1825px #fff,
    2187px 1171px #fff,
    779px 593px #fff,
    349px 2073px #fff,
    1421px 842px #fff,
    943px 330px #fff,
    1137px 1343px #fff,
    550px 1711px #fff,
    561px 1896px #fff,
    1605px 2254px #fff,
    2222px 1862px #fff,
    1679px 691px #fff,
    206px 1733px #fff,
    1819px 1012px #fff,
    420px 419px #fff,
    959px 2490px #fff,
    828px 2209px #fff,
    250px 1438px #fff,
    169px 1774px #fff,
    1672px 2276px #fff,
    2180px 869px #fff,
    2439px 1004px #fff,
    1565px 966px #fff,
    2220px 902px #fff,
    869px 1414px #fff,
    706px 2150px #fff,
    565px 1717px #fff,
    1547px 2151px #fff,
    540px 1118px #fff,
    1943px 1944px #fff,
    1336px 152px #fff,
    1221px 1422px #fff,
    1437px 1651px #fff,
    1314px 766px #fff,
    1209px 1713px #fff,
    883px 2557px #fff,
    280px 1940px #fff,
    2275px 2527px #fff,
    1531px 1764px #fff,
    1088px 901px #fff,
    1859px 1172px #fff,
    838px 1814px #fff,
    676px 1207px #fff,
    2379px 1501px #fff,
    1900px 284px #fff,
    1541px 1703px #fff,
    181px 1591px #fff,
    939px 1779px #fff,
    1280px 236px #fff,
    471px 1259px #fff,
    1382px 2356px #fff,
    1864px 638px #fff,
    165px 553px #fff,
    1888px 937px #fff,
    304px 865px #fff,
    1567px 1390px #fff,
    2517px 1676px #fff,
    498px 2196px #fff,
    857px 1596px #fff,
    326px 720px #fff,
    1948px 1300px #fff,
    2486px 1237px #fff,
    1146px 592px #fff,
    1834px 1477px #fff,
    320px 1793px #fff,
    614px 2327px #fff,
    1313px 901px #fff,
    1268px 265px #fff,
    1690px 1691px #fff,
    2513px 2298px #fff,
    1315px 163px #fff,
    2186px 2364px #fff,
    173px 629px #fff,
    2220px 2067px #fff,
    1525px 2420px #fff,
    1473px 1584px #fff,
    779px 1770px #fff,
    1331px 2339px #fff,
    2138px 1340px #fff,
    342px 823px #fff,
    129px 726px #fff,
    95px 2006px #fff,
    97px 1474px #fff,
    873px 234px #fff,
    1909px 1373px #fff,
    222px 1867px #fff,
    2307px 106px #fff,
    420px 814px #fff,
    1311px 447px #fff,
    2273px 2047px #fff,
    999px 2171px #fff,
    816px 1820px #fff,
    502px 998px #fff,
    1763px 1524px #fff,
    1298px 1803px #fff,
    529px 227px #fff,
    1994px 2479px #fff,
    1970px 608px #fff,
    256px 458px #fff,
    1031px 1816px #fff,
    1311px 2319px #fff,
    1061px 2130px #fff,
    329px 1319px #fff,
    2137px 1679px #fff,
    81px 993px #fff,
    2199px 1965px #fff,
    136px 2114px #fff,
    2305px 777px #fff,
    996px 817px #fff,
    1609px 2314px #fff,
    1370px 239px #fff,
    333px 814px #fff,
    1831px 1074px #fff,
    343px 879px #fff,
    2063px 84px #fff,
    2448px 567px #fff,
    1480px 1181px #fff,
    612px 1960px #fff,
    980px 2036px #fff,
    1578px 1954px #fff,
    972px 2437px #fff,
    88px 1542px #fff,
    2373px 734px #fff,
    772px 1393px #fff,
    129px 145px #fff,
    1508px 1696px #fff,
    712px 1660px #fff,
    1715px 263px #fff,
    205px 1899px #fff,
    1370px 2177px #fff,
    2169px 1352px #fff,
    1514px 1457px #fff,
    24px 1211px #fff,
    2265px 1074px #fff,
    2086px 1282px #fff,
    2478px 1639px #fff,
    2516px 2259px #fff,
    1482px 1413px #fff,
    1989px 227px #fff,
    662px 1526px #fff,
    1158px 1262px #fff,
    1045px 2201px #fff,
    529px 614px #fff,
    12px 2559px #fff,
    1485px 2138px #fff,
    1473px 2543px #fff,
    904px 2159px #fff,
    1078px 1151px #fff,
    1766px 748px #fff,
    6px 2555px #fff,
    1165px 1688px #fff,
    1785px 851px #fff,
    1913px 1778px #fff,
    626px 2303px #fff,
    1128px 2046px #fff,
    115px 1417px #fff,
    1984px 1942px #fff,
    135px 1583px #fff,
    2042px 1712px #fff,
    110px 1405px #fff,
    132px 2369px #fff,
    45px 2389px #fff,
    558px 1911px #fff,
    412px 1090px #fff,
    87px 200px #fff,
    1240px 1904px #fff,
    2058px 1011px #fff,
    690px 859px #fff,
    1907px 505px #fff,
    329px 1992px #fff,
    1500px 1024px #fff,
    948px 227px #fff,
    2086px 1367px #fff,
    2385px 1927px #fff,
    103px 1721px #fff,
    89px 2344px #fff,
    827px 1571px #fff,
    2305px 1255px #fff,
    1573px 1552px #fff,
    1054px 2109px #fff,
    1463px 751px #fff,
    218px 1673px #fff,
    1855px 303px #fff,
    1742px 315px #fff,
    899px 310px #fff,
    1077px 1196px #fff,
    1687px 1016px #fff,
    1212px 2138px #fff,
    1735px 1457px #fff,
    1071px 1628px #fff,
    1196px 1265px #fff,
    488px 1960px #fff,
    1268px 242px #fff,
    1225px 1775px #fff,
    1903px 1448px #fff,
    468px 646px #fff,
    92px 1570px #fff,
    1215px 2227px #fff,
    225px 2299px #fff,
    457px 776px #fff,
    163px 642px #fff,
    307px 1315px #fff,
    1087px 1585px #fff,
    2044px 597px #fff,
    1021px 1715px #fff,
    1452px 1490px #fff,
    1247px 958px #fff,
    346px 672px #fff,
    2214px 2077px #fff,
    2548px 1360px #fff,
    2411px 224px #fff,
    288px 1544px #fff,
    272px 1879px #fff,
    2397px 2358px #fff,
    227px 908px #fff,
    461px 1149px #fff,
    206px 2054px #fff,
    1216px 1404px #fff,
    1667px 652px #fff,
    1173px 1836px #fff,
    850px 168px #fff,
    1349px 1269px #fff,
    872px 311px #fff,
    987px 1880px #fff,
    1323px 514px #fff,
    1137px 783px #fff,
    1975px 907px #fff,
    518px 923px #fff,
    2164px 2116px #fff,
    2559px 351px #fff,
    1330px 1512px #fff,
    107px 434px #fff,
    2324px 733px #fff,
    2250px 847px #fff,
    2483px 1331px #fff,
    2360px 136px #fff,
    1213px 776px #fff,
    1167px 514px #fff,
    2555px 1396px #fff,
    2467px 1590px #fff,
    1603px 2368px #fff,
    749px 1423px #fff,
    1526px 451px #fff,
    449px 1807px #fff,
    1561px 815px #fff,
    2047px 469px #fff,
    390px 2174px #fff,
    80px 1609px #fff,
    1704px 1212px #fff,
    2402px 591px #fff,
    1035px 1872px #fff,
    1253px 2073px #fff,
    2285px 1592px #fff,
    1043px 2112px #fff,
    1496px 1470px #fff,
    2274px 817px #fff,
    1936px 508px #fff,
    2207px 2438px #fff,
    1937px 1299px #fff,
    2025px 376px #fff,
    2383px 938px #fff,
    2207px 361px #fff,
    185px 1770px #fff,
    2367px 2193px #fff,
    33px 1711px #fff,
    1278px 49px #fff,
    1415px 1531px #fff,
    2421px 1299px #fff,
    1773px 1901px #fff,
    1661px 1303px #fff,
    2426px 1999px #fff,
    1947px 2142px #fff,
    1394px 2487px #fff,
    2528px 1922px #fff,
    1543px 2338px #fff,
    2403px 424px #fff,
    1066px 1978px #fff,
    2544px 1365px #fff,
    1221px 2338px #fff,
    2051px 1484px #fff,
    479px 887px #fff,
    2085px 1759px #fff,
    1335px 684px #fff,
    659px 612px #fff,
    1530px 2183px #fff,
    1935px 1155px #fff,
    1797px 1664px #fff,
    1229px 56px #fff,
    915px 2464px #fff,
    1923px 202px #fff,
    1952px 75px #fff,
    1689px 1768px #fff,
    692px 2161px #fff,
    460px 607px #fff,
    2384px 1628px #fff,
    318px 2499px #fff,
    1583px 2536px #fff,
    1869px 1037px #fff,
    1277px 965px #fff,
    917px 1983px #fff,
    1466px 2188px #fff,
    785px 1517px #fff,
    2233px 1392px #fff,
    1052px 1926px #fff,
    362px 300px #fff,
    2433px 2277px #fff,
    1861px 2458px #fff,
    273px 1566px #fff,
    2100px 2060px #fff,
    1453px 1602px #fff,
    584px 2018px #fff,
    1845px 467px #fff,
    348px 744px #fff,
    793px 144px #fff,
    959px 1158px #fff,
    2387px 1013px #fff,
    386px 1581px #fff,
    2511px 112px #fff,
    1819px 1118px #fff,
    1106px 395px #fff,
    1153px 2157px #fff,
    1155px 964px #fff,
    2369px 2217px #fff,
    976px 154px #fff,
    274px 1423px #fff,
    924px 2381px #fff,
    1343px 944px #fff,
    1469px 63px #fff,
    777px 1719px #fff,
    317px 163px #fff,
    1965px 1705px #fff,
    595px 1512px #fff,
    662px 883px #fff,
    1416px 919px #fff,
    2241px 2447px #fff,
    2379px 2060px #fff,
    742px 1286px #fff,
    990px 901px #fff,
    711px 521px #fff,
    1524px 2287px #fff,
    1459px 32px #fff,
    616px 309px #fff,
    1584px 1286px #fff,
    2219px 2330px #fff,
    471px 1697px #fff,
    55px 1928px #fff,
    594px 944px #fff,
    1873px 2013px #fff,
    2367px 1267px #fff,
    268px 2235px #fff,
    1955px 1092px #fff,
    69px 2458px #fff,
    1111px 1883px #fff,
    2072px 1717px #fff,
    1356px 2166px #fff,
    702px 7px #fff,
    1043px 698px #fff,
    1508px 1154px #fff,
    1809px 1380px #fff,
    2514px 571px #fff,
    1566px 61px #fff,
    90px 664px #fff,
    2238px 1938px #fff,
    753px 2301px #fff,
    2085px 2531px #fff,
    1737px 2166px #fff,
    698px 932px #fff,
    1711px 1682px #fff,
    820px 125px #fff,
    1230px 2354px #fff,
    2030px 988px #fff,
    576px 1961px #fff,
    1318px 2470px #fff,
    389px 1341px #fff,
    658px 1871px #fff,
    1212px 1674px #fff,
    1151px 1114px #fff,
    535px 1949px #fff,
    596px 1263px #fff,
    1346px 873px #fff,
    1956px 582px #fff,
    1167px 756px #fff,
    763px 290px #fff,
    875px 1341px #fff,
    2415px 1351px #fff,
    2334px 1087px #fff,
    2326px 2519px #fff,
    652px 2210px #fff,
    1452px 2495px #fff,
    1609px 1864px #fff,
    668px 607px #fff,
    1722px 1400px #fff,
    757px 1739px #fff,
    2280px 994px #fff,
    764px 1168px #fff,
    294px 2393px #fff,
    2001px 2219px #fff,
    1443px 2377px #fff,
    1815px 789px #fff,
    1290px 348px #fff,
    2435px 1948px #fff,
    2203px 775px #fff,
    1077px 1292px #fff,
    1551px 19px #fff,
    499px 2235px #fff,
    2442px 1559px #fff,
    2118px 241px #fff,
    2262px 190px #fff,
    1856px 1697px #fff,
    1892px 564px #fff,
    1223px 1886px #fff,
    1740px 932px #fff,
    2369px 1985px #fff,
    2018px 716px #fff,
    779px 2000px #fff,
    1843px 1726px #fff,
    1214px 2504px #fff,
    1102px 408px #fff,
    938px 953px #fff,
    849px 2007px #fff,
    1616px 2429px #fff,
    2338px 883px #fff,
    689px 2149px #fff,
    2458px 1106px #fff,
    1434px 1093px #fff,
    67px 867px #fff,
    2417px 1856px #fff,
    2213px 947px #fff,
    296px 1636px #fff,
    15px 993px #fff,
    1045px 1771px #fff,
    1352px 2161px #fff,
    1587px 329px #fff,
    1824px 1390px #fff,
    482px 1050px #fff,
    1427px 2196px #fff,
    162px 2402px #fff,
    1648px 67px #fff,
    2057px 2522px #fff,
    310px 1367px #fff,
    2371px 838px #fff,
    99px 868px #fff,
    1696px 2093px #fff,
    1703px 649px #fff,
    2063px 2063px #fff,
    318px 209px #fff,
    1769px 1108px #fff,
    1798px 1124px #fff,
    1866px 1387px #fff,
    786px 895px #fff,
    150px 2259px #fff,
    2167px 1768px #fff,
    1041px 2462px #fff,
    2507px 1709px #fff,
    776px 1718px #fff,
    1747px 1118px #fff,
    1933px 1651px #fff,
    772px 576px #fff,
    705px 61px #fff,
    353px 1627px #fff,
    474px 2496px #fff,
    391px 1121px #fff,
    1749px 2289px #fff,
    1444px 2248px #fff,
    84px 641px #fff,
    2092px 1056px #fff,
    1042px 2547px #fff,
    366px 876px #fff,
    1084px 599px #fff,
    2451px 968px #fff,
    1781px 481px #fff,
    2520px 1356px #fff,
    1680px 957px #fff,
    1346px 1410px #fff,
    2533px 736px #fff,
    1519px 2381px #fff,
    1131px 2356px #fff,
    1707px 845px #fff,
    374px 854px #fff,
    928px 1586px #fff,
    1936px 883px #fff,
    1019px 1581px #fff,
    279px 814px #fff,
    909px 156px #fff,
    2092px 787px #fff,
    1787px 1751px #fff,
    313px 162px #fff,
    1824px 1168px #fff,
    1975px 2349px #fff,
    388px 2199px #fff,
    337px 2178px #fff,
    1074px 2497px #fff,
    2102px 2471px #fff,
    1234px 1594px #fff,
    1770px 2023px #fff,
    1075px 1926px #fff,
    513px 225px #fff,
    282px 1540px #fff,
    2414px 1783px #fff,
    1682px 2091px #fff,
    1595px 682px #fff,
    2342px 80px #fff,
    2440px 97px #fff,
    1984px 2012px #fff,
    1500px 1019px #fff,
    2298px 859px #fff,
    2311px 1018px #fff,
    2257px 836px #fff,
    61px 2185px #fff,
    471px 541px #fff,
    369px 1054px #fff,
    1415px 306px #fff,
    2161px 242px #fff,
    938px 2255px #fff,
    412px 704px #fff,
    767px 1587px #fff,
    45px 1851px #fff,
    624px 146px #fff,
    574px 1835px #fff,
    2555px 158px #fff,
    1462px 2339px #fff,
    2329px 2244px #fff,
    278px 452px #fff,
    2360px 1603px #fff,
    1711px 1012px #fff,
    853px 532px #fff,
    1217px 25px #fff,
    922px 1092px #fff,
    704px 1866px #fff,
    728px 364px #fff,
    1984px 1354px #fff,
    1668px 1004px #fff,
    836px 2045px #fff,
    1369px 1743px #fff,
    217px 2117px #fff,
    1454px 539px #fff,
    907px 1797px #fff,
    2305px 647px #fff,
    1816px 2515px #fff,
    1465px 371px #fff,
    380px 687px #fff,
    1508px 320px #fff,
    885px 2438px #fff,
    795px 2269px #fff,
    711px 1202px #fff,
    1904px 1317px #fff,
    334px 1821px #fff,
    1606px 1557px #fff,
    1533px 317px #fff,
    555px 239px #fff,
    1513px 1597px #fff,
    847px 2051px #fff,
    1482px 634px #fff,
    1026px 1794px #fff,
    1148px 1963px #fff,
    2034px 1218px #fff,
    283px 792px #fff,
    1320px 2300px #fff,
    1244px 9px #fff,
    1266px 2548px #fff,
    1507px 1151px #fff,
    897px 1074px #fff,
    812px 808px #fff,
    2544px 675px #fff,
    1104px 854px #fff,
    1829px 783px #fff,
    334px 139px #fff,
    827px 1532px #fff,
    708px 522px #fff,
    1812px 1193px #fff,
    1220px 798px #fff,
    306px 1724px #fff,
    421px 1711px #fff,
    115px 2254px #fff,
    1929px 1839px #fff,
    771px 488px #fff,
    722px 2184px #fff,
    2469px 2063px #fff,
    1905px 2244px #fff,
    210px 1999px #fff,
    226px 241px #fff,
    1395px 175px #fff,
    1139px 2000px #fff,
    458px 3px #fff,
    2067px 1626px #fff,
    831px 1079px #fff,
    1871px 2319px #fff,
    431px 1979px #fff,
    2352px 388px #fff,
    2028px 2534px #fff,
    2508px 8px #fff,
    1608px 2535px #fff,
    93px 895px #fff,
    1397px 758px #fff,
    2420px 707px #fff,
    849px 2409px #fff,
    1623px 1063px #fff,
    1945px 1703px #fff,
    1767px 2490px #fff,
    19px 1582px #fff,
    1572px 2220px #fff,
    1560px 2448px #fff,
    1311px 170px #fff,
    1217px 2106px #fff,
    715px 768px #fff,
    1076px 194px #fff,
    1658px 2369px #fff,
    407px 578px #fff,
    1274px 2502px #fff,
    1410px 484px #fff,
    201px 278px #fff,
    1386px 1955px #fff,
    1615px 1108px #fff,
    1738px 1887px #fff,
    626px 2425px #fff,
    124px 1421px #fff,
    392px 2384px #fff,
    37px 1759px #fff,
    934px 139px #fff,
    1718px 497px #fff,
    2185px 695px #fff,
    604px 1652px #fff,
    191px 2553px #fff,
    820px 1748px #fff,
    2461px 1168px #fff,
    77px 998px #fff,
    2149px 416px #fff,
    1353px 1074px #fff,
    971px 1154px #fff,
    1036px 2408px #fff,
    1965px 1510px #fff,
    127px 403px #fff,
    1264px 1633px #fff,
    148px 1686px #fff,
    884px 783px #fff,
    1272px 45px #fff,
    769px 261px #fff,
    2529px 953px #fff,
    204px 306px #fff,
    2187px 302px #fff,
    2048px 914px #fff,
    2206px 2158px #fff,
    1955px 1953px #fff,
    1902px 2021px #fff,
    1988px 2450px #fff,
    1032px 542px #fff,
    1242px 2399px #fff,
    2381px 2093px #fff,
    1029px 992px #fff,
    1669px 1001px #fff,
    763px 2372px #fff,
    2279px 842px #fff,
    2379px 748px #fff,
    908px 485px #fff,
    2394px 476px #fff,
    695px 1752px #fff,
    2190px 2159px #fff,
    1531px 1997px #fff,
    2425px 416px #fff,
    2464px 1281px #fff,
    2276px 2454px #fff,
    1584px 1515px #fff,
    2181px 781px #fff,
    1291px 1035px #fff,
    1459px 952px #fff,
    113px 829px #fff,
    128px 1476px #fff,
    1997px 2386px #fff,
    938px 1678px #fff,
    101px 2463px #fff,
    1200px 2177px #fff,
    2041px 1877px #fff,
    817px 1818px #fff,
    259px 308px #fff,
    780px 542px #fff,
    1068px 1414px #fff,
    2183px 2285px #fff,
    10px 1644px #fff,
    994px 2467px #fff,
    917px 1163px #fff,
    2417px 1564px #fff,
    1000px 332px #fff,
    1881px 2026px #fff,
    1076px 1791px #fff,
    533px 972px #fff,
    731px 1220px #fff,
    2099px 1218px #fff,
    1745px 2218px #fff,
    364px 2435px #fff,
    1502px 1566px #fff,
    1586px 1859px #fff,
    683px 311px #fff,
    2007px 2416px #fff,
    2300px 1486px #fff,
    270px 2034px #fff,
    47px 1828px #fff,
    700px 1959px #fff,
    1457px 854px #fff,
    296px 520px #fff,
    688px 1646px #fff,
    415px 1408px #fff,
    1137px 1003px #fff,
    148px 2308px #fff,
    116px 868px #fff,
    2044px 347px #fff,
    2290px 50px #fff,
    66px 278px #fff,
    183px 853px #fff,
    1540px 720px #fff,
    1200px 1693px #fff,
    418px 393px #fff,
    205px 156px #fff,
    1160px 983px #fff,
    344px 864px #fff,
    676px 640px #fff,
    2068px 2479px #fff,
    2515px 463px #fff,
    425px 1225px #fff,
    842px 1400px #fff,
    2251px 1028px #fff,
    138px 197px #fff,
    2041px 1355px #fff,
    2185px 2062px #fff,
    2340px 418px #fff,
    1190px 554px #fff,
    304px 392px #fff,
    1660px 753px #fff,
    2553px 889px #fff,
    464px 1813px #fff,
    2550px 1853px #fff,
    1972px 774px #fff,
    2090px 2055px #fff,
    2201px 483px #fff,
    1504px 76px #fff,
    1192px 511px #fff,
    1678px 537px #fff,
    458px 1885px #fff,
    1446px 1749px #fff,
    2533px 2392px #fff,
    1204px 781px #fff,
    2231px 914px #fff,
    60px 754px #fff,
    1435px 1166px #fff,
    302px 2159px #fff,
    253px 195px #fff,
    2151px 161px #fff,
    421px 218px #fff,
    1611px 1250px #fff,
    2212px 1156px #fff,
    335px 2133px #fff,
    542px 2486px #fff,
    1003px 109px #fff,
    875px 2428px #fff,
    1296px 1259px #fff,
    2228px 1468px #fff,
    173px 1822px #fff,
    219px 573px #fff,
    218px 2244px #fff,
    1308px 357px #fff,
    496px 879px #fff,
    2206px 171px #fff,
    1282px 2052px #fff,
    717px 1551px #fff,
    2043px 2395px #fff,
    1852px 1378px #fff,
    688px 277px #fff,
    113px 255px #fff,
    1945px 1317px #fff,
    2161px 215px #fff,
    1552px 924px #fff,
    892px 656px #fff,
    775px 2339px #fff,
    1473px 2253px #fff,
    1930px 322px #fff,
    1433px 3px #fff,
    297px 591px #fff,
    1156px 2435px #fff,
    1098px 1789px #fff,
    164px 670px #fff,
    461px 1658px #fff,
    752px 901px #fff,
    1142px 105px #fff,
    900px 746px #fff,
    431px 1728px #fff,
    2036px 1576px #fff,
    2211px 1216px #fff,
    788px 1064px #fff,
    1892px 1958px #fff,
    2049px 1639px #fff,
    788px 2062px #fff,
    945px 2077px #fff,
    2225px 1979px #fff,
    1180px 1820px #fff,
    49px 1032px #fff,
    1289px 1751px #fff,
    296px 1633px #fff,
    1541px 1134px #fff,
    1277px 96px #fff,
    2015px 968px #fff,
    590px 2442px #fff,
    1787px 1148px #fff,
    625px 197px #fff,
    24px 854px #fff,
    1857px 920px #fff,
    2228px 2341px #fff,
    223px 2190px #fff,
    2518px 236px #fff,
    569px 1133px #fff,
    1121px 441px #fff,
    1617px 715px #fff,
    768px 558px #fff,
    1316px 492px #fff,
    163px 1100px #fff,
    2234px 172px #fff,
    1012px 1017px #fff,
    1505px 624px #fff,
    2034px 1693px #fff,
    1671px 681px #fff,
    1375px 1634px #fff,
    2497px 2327px #fff,
    152px 798px #fff,
    1552px 1240px #fff,
    2409px 2474px #fff,
    221px 2040px #fff,
    1977px 1404px #fff,
    1814px 1228px #fff,
    547px 828px #fff,
    2538px 454px #fff,
    1318px 159px #fff,
    899px 89px #fff,
    1874px 1493px #fff,
    415px 1937px #fff,
    1106px 1091px #fff,
    1900px 466px #fff,
    1188px 2150px #fff,
    1897px 2128px #fff,
    1526px 964px #fff,
    2193px 483px #fff,
    144px 2444px #fff,
    1005px 1128px #fff,
    2461px 750px #fff,
    863px 1977px #fff,
    859px 1480px #fff,
    2050px 273px #fff,
    542px 2307px #fff,
    1207px 517px #fff,
    95px 1361px #fff,
    2037px 1046px #fff,
    509px 122px #fff,
    407px 499px #fff,
    2285px 1214px #fff,
    292px 2201px #fff,
    884px 333px #fff,
    1783px 1011px #fff,
    1673px 2174px #fff,
    2209px 2216px #fff,
    1767px 1303px #fff,
    1426px 691px #fff,
    1742px 1246px #fff,
    2306px 66px #fff,
    770px 1051px #fff,
    1086px 2053px #fff,
    87px 1623px #fff,
    760px 1912px #fff,
    2458px 2445px #fff,
    5px 2003px #fff,
    968px 1999px #fff,
    2079px 2501px #fff,
    918px 714px #fff,
    54px 384px #fff,
    276px 1795px #fff,
    574px 1686px #fff,
    555px 2298px #fff,
    1056px 2455px #fff,
    540px 1881px #fff,
    704px 740px #fff,
    2476px 2498px #fff,
    827px 325px #fff,
    179px 2424px #fff,
    1537px 533px #fff,
    2095px 1431px #fff,
    1215px 1702px #fff,
    1241px 310px #fff,
    1352px 223px #fff,
    1003px 126px #fff,
    2105px 1432px #fff,
    1230px 2560px #fff,
    1725px 2099px #fff,
    2225px 583px #fff,
    2154px 376px #fff,
    1537px 2325px #fff,
    272px 152px #fff,
    2444px 2121px #fff,
    1914px 1575px #fff,
    548px 420px #fff,
    161px 1083px #fff,
    1893px 2092px #fff,
    298px 1734px #fff,
    2390px 2038px #fff,
    738px 876px #fff,
    1562px 628px #fff,
    1137px 2346px #fff,
    1110px 365px #fff,
    2297px 1025px #fff,
    253px 1943px #fff,
    1588px 2520px #fff,
    477px 1982px #fff,
    99px 1493px #fff,
    2194px 564px #fff,
    300px 634px #fff,
    1587px 1479px #fff,
    2131px 1233px #fff,
    1708px 2218px #fff,
    1853px 624px #fff,
    2333px 24px #fff,
    235px 866px #fff,
    652px 2301px #fff,
    1342px 1909px #fff,
    1351px 1733px #fff,
    1719px 87px #fff,
    766px 2539px #fff,
    1253px 943px #fff,
    1270px 1982px #fff,
    592px 1882px #fff,
    2224px 765px #fff,
    515px 2127px #fff,
    866px 1796px #fff,
    822px 769px #fff,
    618px 762px #fff,
    1596px 2339px #fff,
    749px 2445px #fff,
    2041px 1205px #fff,
    1061px 2241px #fff,
    1482px 1263px #fff,
    1635px 259px #fff,
    580px 869px #fff,
    1541px 1204px #fff,
    2009px 132px #fff,
    402px 936px #fff,
    1599px 1065px #fff,
    1491px 124px #fff,
    1601px 328px #fff,
    614px 357px #fff,
    118px 6px #fff,
    847px 772px #fff,
    1233px 2199px #fff,
    590px 1350px #fff,
    1452px 1415px #fff,
    152px 472px #fff,
    2116px 1690px #fff,
    1934px 1105px #fff,
    435px 1152px #fff,
    2422px 603px #fff,
    1167px 1186px #fff,
    2158px 908px #fff,
    232px 645px #fff,
    1289px 1472px #fff,
    598px 2375px #fff,
    1950px 610px #fff,
    931px 928px #fff,
    462px 875px #fff,
    1619px 2168px #fff,
    823px 1906px #fff,
    962px 637px #fff,
    1032px 396px #fff,
    957px 2437px #fff,
    597px 1858px #fff,
    373px 1872px #fff,
    2430px 695px #fff,
    152px 1785px #fff,
    1618px 476px #fff,
    961px 1749px #fff,
    2118px 533px #fff,
    2081px 2429px #fff,
    2505px 1590px #fff,
    1110px 764px #fff,
    2351px 1018px #fff,
    181px 2120px #fff,
    399px 1867px #fff,
    8px 1797px #fff,
    1306px 1942px #fff,
    1786px 2464px #fff,
    691px 2553px #fff,
    60px 515px #fff,
    1462px 605px #fff,
    2478px 719px #fff,
    1992px 1830px #fff,
    2484px 818px #fff,
    1753px 455px #fff,
    1876px 666px #fff,
    1179px 742px #fff,
    1818px 479px #fff,
    1916px 177px #fff,
    892px 1002px #fff,
    977px 2458px #fff,
    1526px 824px #fff,
    75px 1495px #fff,
    1455px 740px #fff,
    1404px 1842px #fff,
    1013px 2207px #fff,
    1284px 964px #fff,
    763px 2252px #fff,
    188px 106px #fff,
    674px 237px #fff,
    2407px 2483px #fff,
    1695px 853px #fff,
    736px 1330px #fff,
    1572px 1529px #fff,
    861px 2406px #fff,
    335px 1225px #fff,
    149px 2436px #fff,
    24px 431px #fff,
    655px 2291px #fff,
    1067px 899px #fff,
    1681px 699px #fff,
    772px 1509px #fff,
    838px 2473px #fff,
    1081px 140px #fff,
    1636px 2547px #fff,
    767px 603px #fff,
    2076px 2192px #fff,
    2135px 266px #fff,
    384px 2419px #fff,
    1303px 2293px #fff,
    2012px 1208px #fff,
    2468px 2312px #fff,
    1989px 1679px #fff,
    828px 2052px #fff,
    1676px 479px #fff,
    1161px 1702px #fff,
    2480px 1987px #fff,
    2316px 1260px #fff,
    1956px 1382px #fff,
    2545px 1355px #fff,
    1621px 331px #fff,
    2423px 599px #fff,
    1908px 526px #fff,
    601px 1708px #fff,
    1304px 951px #fff,
    81px 1309px #fff,
    1184px 734px #fff,
    513px 203px #fff,
    1185px 2038px #fff,
    1359px 478px #fff,
    1498px 783px #fff,
    807px 337px #fff,
    1658px 1107px #fff,
    207px 566px #fff,
    2192px 1706px #fff,
    241px 694px #fff,
    1179px 65px #fff,
    1043px 823px #fff,
    1235px 1478px #fff,
    488px 2164px #fff,
    1460px 869px #fff,
    793px 1312px #fff,
    1234px 2206px #fff,
    1742px 851px #fff,
    919px 963px #fff,
    2379px 621px #fff,
    2079px 1281px #fff,
    1501px 465px #fff,
    1046px 1831px #fff,
    1761px 519px #fff,
    2560px 533px #fff,
    716px 2560px #fff,
    2274px 1834px #fff,
    571px 1077px #fff,
    1774px 988px #fff,
    1357px 566px #fff,
    2557px 1077px #fff,
    876px 1835px #fff,
    1072px 1824px #fff,
    769px 601px #fff,
    1913px 810px #fff,
    2334px 379px #fff,
    2159px 1564px #fff,
    1477px 146px #fff,
    234px 897px #fff,
    965px 959px #fff,
    996px 927px #fff,
    1828px 2375px #fff,
    523px 126px #fff,
    1693px 925px #fff,
    1521px 45px #fff,
    374px 1470px #fff,
    1867px 324px #fff,
    1312px 2518px #fff,
    1858px 621px #fff,
    2372px 22px #fff,
    2022px 2410px #fff,
    1471px 245px #fff,
    1911px 1px #fff,
    1996px 53px #fff,
    2327px 242px #fff,
    1546px 1626px #fff,
    1139px 2011px #fff,
    626px 2244px #fff,
    859px 48px #fff,
    2548px 1183px #fff,
    877px 267px #fff,
    1400px 2235px #fff,
    527px 2423px #fff,
    2418px 807px #fff,
    235px 2058px #fff,
    782px 1893px #fff,
    2196px 1475px #fff,
    250px 1016px #fff,
    693px 591px #fff,
    1137px 2088px #fff,
    1381px 2270px #fff,
    810px 828px #fff,
    1033px 1753px #fff,
    772px 413px #fff,
    1630px 2214px #fff,
    486px 722px #fff,
    104px 357px #fff,
    1332px 601px #fff,
    2510px 2018px #fff,
    813px 2030px #fff,
    2214px 1842px #fff,
    2104px 56px #fff,
    196px 1441px #fff,
    706px 1001px #fff,
    1845px 1691px #fff,
    758px 1861px #fff,
    141px 898px #fff,
    1577px 909px #fff,
    820px 181px #fff,
    2110px 1452px #fff,
    2011px 503px #fff,
    1304px 1516px #fff,
    2288px 1166px #fff,
    694px 2386px #fff,
    647px 2193px #fff,
    101px 2290px #fff,
    1192px 202px #fff,
    2510px 653px #fff,
    980px 89px #fff,
    1137px 1471px #fff,
    2351px 1451px #fff,
    2415px 553px #fff,
    1852px 1882px #fff,
    2511px 1779px #fff,
    209px 2175px #fff,
    1568px 2089px #fff,
    2510px 231px #fff,
    1008px 1148px #fff,
    1852px 1743px #fff,
    2230px 2393px #fff,
    1702px 2158px #fff,
    2433px 716px #fff,
    2040px 102px #fff,
    2px 1887px #fff,
    1509px 722px #fff,
    939px 612px #fff,
    48px 447px #fff,
    508px 491px #fff,
    716px 1380px #fff,
    1231px 19px #fff,
    1123px 324px #fff,
    1228px 2066px #fff,
    555px 1982px #fff,
    435px 1848px #fff,
    300px 744px #fff,
    2446px 1699px #fff,
    153px 324px #fff,
    231px 1120px #fff,
    1298px 251px #fff,
    1012px 1947px #fff,
    347px 500px #fff,
    2077px 1058px #fff,
    846px 2425px #fff,
    2507px 2124px #fff,
    609px 1800px #fff,
    1969px 2455px #fff,
    1756px 86px #fff,
    250px 88px #fff,
    971px 490px #fff,
    2482px 2411px #fff,
    111px 396px #fff,
    1682px 1082px #fff,
    1264px 1542px #fff,
    253px 2188px #fff,
    840px 1709px #fff,
    1189px 2329px #fff,
    785px 1641px #fff,
    1216px 453px #fff,
    634px 2398px #fff,
    2403px 195px #fff,
    621px 403px #fff,
    2364px 425px #fff,
    170px 1454px #fff,
    1681px 1714px #fff,
    1585px 2292px #fff,
    91px 773px #fff,
    592px 2413px #fff,
    572px 2439px #fff,
    1574px 1005px #fff,
    1912px 1797px #fff,
    1658px 480px #fff,
    670px 959px #fff,
    751px 1595px #fff,
    1502px 1794px #fff,
    2463px 457px #fff,
    648px 133px #fff,
    2436px 1177px #fff,
    1756px 1599px #fff,
    785px 2386px #fff,
    2392px 721px #fff,
    1134px 2038px #fff,
    1980px 1873px #fff,
    1395px 909px #fff,
    430px 1783px #fff,
    77px 750px #fff,
    2325px 1566px #fff,
    1411px 2029px #fff,
    2357px 2515px #fff,
    2197px 2383px #fff,
    2342px 2196px #fff,
    2142px 2463px #fff,
    2546px 1933px #fff,
    2554px 1730px #fff,
    490px 1705px #fff,
    1061px 986px #fff,
    684px 1435px #fff,
    1743px 1248px #fff,
    593px 1157px #fff,
    2100px 193px #fff,
    1876px 2057px #fff,
    1637px 2374px #fff,
    1983px 706px #fff,
    2224px 1921px #fff,
    599px 688px #fff,
    2157px 659px #fff,
    2033px 968px #fff,
    737px 1276px #fff,
    945px 1385px #fff,
    2000px 1320px #fff,
    1211px 608px #fff,
    758px 509px #fff,
    2256px 126px #fff,
    1872px 2076px #fff,
    348px 931px #fff,
    755px 2552px #fff,
    262px 1769px #fff,
    763px 1733px #fff,
    2276px 54px #fff,
    1278px 2220px #fff,
    446px 1467px #fff,
    1977px 389px #fff,
    371px 1395px #fff,
    1683px 1903px #fff,
    945px 15px #fff,
    1842px 247px #fff,
    1614px 1692px #fff,
    1602px 1285px #fff,
    2437px 835px #fff,
    293px 1712px #fff,
    1853px 2264px #fff,
    830px 1921px #fff,
    229px 2465px #fff,
    472px 1639px #fff,
    1641px 2361px #fff,
    626px 650px #fff,
    2500px 402px #fff,
    1272px 2301px #fff,
    1612px 1190px #fff,
    269px 1504px #fff,
    348px 2452px #fff,
    1028px 2456px #fff,
    1253px 1793px #fff,
    2316px 1679px #fff,
    2535px 1972px #fff,
    1002px 430px #fff,
    1119px 1171px #fff,
    2355px 2069px #fff,
    2533px 2315px #fff,
    177px 573px #fff,
    95px 1583px #fff,
    1498px 692px #fff,
    1199px 715px #fff,
    1655px 407px #fff,
    859px 358px #fff,
    1134px 1945px #fff,
    162px 2084px #fff,
    2172px 544px #fff,
    2445px 159px #fff,
    569px 2079px #fff,
    1743px 2098px #fff,
    590px 1037px #fff,
    1662px 353px #fff,
    847px 1886px #fff,
    705px 760px #fff,
    1130px 2017px #fff,
    1366px 676px #fff,
    1421px 1px #fff,
    679px 1420px #fff,
    2498px 2398px #fff,
    36px 2341px #fff,
    264px 536px #fff,
    335px 1373px #fff,
    676px 1608px #fff,
    988px 1621px #fff,
    1235px 173px #fff,
    1069px 1714px #fff,
    1160px 2380px #fff,
    1253px 236px #fff,
    716px 1598px #fff,
    815px 166px #fff,
    2465px 840px #fff,
    1929px 2550px #fff,
    1997px 1979px #fff,
    819px 2287px #fff,
    229px 2234px #fff,
    454px 278px #fff,
    1721px 748px #fff,
    2260px 757px #fff,
    627px 1490px #fff,
    1419px 149px #fff,
    2490px 617px #fff,
    277px 2082px #fff,
    1183px 1766px #fff,
    100px 382px #fff,
    1294px 118px #fff,
    239px 530px #fff,
    855px 418px #fff,
    2105px 1802px #fff,
    1598px 766px #fff,
    2444px 356px #fff,
    2430px 1306px #fff,
    1921px 641px #fff,
    2292px 1763px #fff,
    1139px 1941px #fff,
    218px 2160px #fff,
    1601px 204px #fff,
    2180px 2104px #fff,
    908px 1843px #fff,
    443px 769px #fff,
    972px 171px #fff,
    1226px 1854px #fff,
    2278px 2522px #fff,
    2137px 2550px #fff,
    605px 2495px #fff,
    1049px 597px #fff,
    1852px 1928px #fff,
    1503px 1858px #fff,
    329px 394px #fff,
    2213px 1331px #fff,
    2453px 2450px #fff,
    868px 1224px #fff,
    251px 60px #fff,
    158px 1007px #fff,
    2165px 2222px #fff,
    1251px 2433px #fff,
    2405px 642px #fff,
    1274px 2199px #fff,
    433px 1599px #fff,
    181px 966px #fff,
    1601px 1302px #fff,
    2035px 78px #fff,
    248px 781px #fff,
    1845px 1213px #fff,
    180px 395px #fff,
    2292px 1156px #fff,
    786px 133px #fff,
    742px 1627px #fff,
    906px 356px #fff,
    2529px 2335px #fff,
    2399px 48px #fff,
    952px 62px #fff,
    440px 381px #fff,
    1267px 1486px #fff,
    734px 2414px #fff,
    369px 1613px #fff,
    1001px 2191px #fff,
    1308px 525px #fff,
    577px 2161px #fff,
    432px 2223px #fff,
    408px 295px #fff,
    1248px 151px #fff,
    600px 9px #fff,
    216px 1050px #fff,
    578px 673px #fff,
    1224px 160px #fff,
    1572px 918px #fff,
    210px 1709px #fff,
    1092px 1433px #fff,
    1968px 2142px #fff,
    702px 1355px #fff,
    867px 392px #fff,
    373px 15px #fff,
    24px 196px #fff,
    455px 1877px #fff,
    2057px 5px #fff,
    1298px 2460px #fff,
    1805px 568px #fff,
    1235px 164px #fff,
    541px 1196px #fff,
    576px 727px #fff,
    155px 999px #fff,
    2552px 142px #fff,
    441px 2172px #fff,
    2057px 2125px #fff,
    1391px 385px #fff,
    946px 955px #fff,
    2341px 837px #fff,
    2346px 1108px #fff,
    994px 2191px #fff,
    340px 722px #fff,
    445px 273px #fff,
    159px 1744px #fff,
    175px 317px #fff,
    1214px 241px #fff,
    681px 57px #fff,
    1605px 1525px #fff,
    2417px 962px #fff,
    2498px 1005px #fff,
    12px 2328px #fff,
    1758px 1607px #fff,
    303px 1590px #fff,
    1187px 2004px #fff,
    1293px 1667px #fff,
    698px 1797px #fff,
    2551px 2089px #fff,
    2095px 1228px #fff,
    436px 2535px #fff,
    516px 2480px #fff,
    1233px 317px #fff,
    1556px 918px #fff,
    513px 148px #fff,
    1290px 780px #fff,
    2424px 1982px #fff,
    2169px 2259px #fff,
    2064px 740px #fff,
    1651px 1046px #fff,
    1145px 573px #fff,
    511px 1398px #fff,
    1323px 99px #fff,
    2524px 280px #fff,
    2391px 1373px #fff,
    1319px 2147px #fff,
    1604px 1917px #fff,
    1631px 1780px #fff,
    1332px 2354px #fff,
    513px 949px #fff,
    2013px 1192px #fff,
    1961px 1076px #fff,
    1728px 2500px #fff,
    506px 609px #fff,
    634px 1899px #fff,
    100px 2212px #fff,
    1017px 2017px #fff,
    924px 1400px #fff,
    2278px 1235px #fff,
    903px 1772px #fff,
    1648px 1058px #fff,
    1601px 434px #fff,
    4px 610px #fff,
    1662px 88px #fff,
    37px 2288px #fff,
    851px 966px #fff,
    563px 37px #fff,
    142px 795px #fff,
    1730px 2362px #fff,
    2007px 1188px #fff,
    1614px 794px #fff,
    1053px 1439px #fff,
    2351px 918px #fff,
    2481px 1066px #fff,
    2442px 1667px #fff,
    1675px 112px #fff,
    2269px 1863px #fff,
    1638px 230px #fff,
    1408px 1630px #fff,
    2345px 2268px #fff,
    1375px 1824px #fff,
    527px 1415px #fff,
    2475px 372px #fff,
    473px 2496px #fff,
    51px 50px #fff,
    1983px 558px #fff,
    157px 1930px #fff,
    530px 2165px #fff,
    1077px 2428px #fff,
    1613px 2013px #fff,
    602px 1767px #fff,
    522px 775px #fff,
    489px 1685px #fff,
    1123px 2149px #fff,
    2097px 1555px #fff,
    2366px 369px #fff,
    469px 633px #fff,
    2320px 232px #fff,
    1039px 57px #fff,
    121px 1303px #fff,
    377px 2494px #fff,
    1820px 1387px #fff,
    1550px 81px #fff,
    684px 1001px #fff,
    1767px 520px #fff,
    2377px 161px #fff,
    1827px 87px #fff,
    1550px 1857px #fff,
    1458px 2103px #fff,
    158px 56px #fff,
    1636px 1437px #fff,
    531px 1099px #fff,
    1794px 1433px #fff,
    1407px 2228px #fff,
    2434px 2054px #fff,
    2473px 928px #fff,
    1436px 741px #fff,
    2419px 2342px #fff,
    620px 704px #fff,
    956px 521px #fff,
    1774px 2054px #fff,
    1402px 1790px #fff,
    1584px 1392px #fff,
    990px 232px #fff,
    1763px 2218px #fff,
    2376px 2093px #fff,
    675px 374px #fff,
    2059px 633px #fff,
    2282px 286px #fff,
    2046px 796px #fff,
    407px 278px #fff,
    1130px 876px #fff,
    1245px 2171px #fff,
    670px 821px #fff,
    1538px 1259px #fff,
    349px 1930px #fff,
    980px 2224px #fff,
    2380px 297px #fff,
    2551px 1017px #fff,
    707px 1146px #fff,
    2481px 1110px #fff,
    1325px 992px #fff,
    2357px 1130px #fff,
    1935px 2413px #fff,
    290px 592px #fff,
    340px 1152px #fff,
    2346px 1414px #fff,
    50px 2349px #fff,
    1429px 2348px #fff,
    1023px 2392px #fff,
    856px 2226px #fff,
    189px 1890px #fff,
    2433px 1214px #fff,
    2250px 2524px #fff,
    14px 18px #fff,
    1288px 2237px #fff,
    1927px 1836px #fff,
    2500px 612px #fff,
    1743px 1771px #fff,
    1680px 868px #fff,
    277px 2049px #fff,
    15px 9px #fff,
    1480px 429px #fff,
    157px 887px #fff,
    841px 1745px #fff,
    1812px 955px #fff,
    160px 1997px #fff,
    382px 1801px #fff,
    788px 437px #fff,
    1020px 1648px #fff,
    1583px 2108px #fff,
    2266px 1968px #fff,
    1768px 2373px #fff,
    1315px 652px #fff,
    1076px 2219px #fff,
    2353px 1732px #fff,
    90px 919px #fff,
    93px 1327px #fff,
    521px 1863px #fff,
    1797px 589px #fff,
    2446px 1059px #fff,
    1947px 173px #fff,
    1768px 561px #fff,
    934px 248px #fff,
    177px 2131px #fff,
    1687px 240px #fff,
    2363px 2207px #fff,
    1261px 1406px #fff,
    1590px 2475px #fff,
    2110px 1981px #fff,
    559px 1256px #fff,
    1774px 2049px #fff,
    890px 590px #fff,
    349px 2186px #fff,
    1648px 2106px #fff,
    2163px 1428px #fff,
    242px 131px #fff,
    935px 489px #fff,
    1454px 1289px #fff,
    1913px 1725px #fff,
    2257px 928px #fff,
    139px 1270px #fff,
    1978px 2158px #fff,
    1414px 1353px #fff,
    812px 1262px #fff,
    268px 364px #fff,
    2393px 242px #fff,
    585px 1906px #fff,
    912px 1657px #fff,
    1713px 2394px #fff,
    453px 1045px #fff,
    1638px 1424px #fff,
    504px 1508px #fff,
    167px 267px #fff,
    1968px 1217px #fff,
    1376px 1108px #fff,
    325px 1371px #fff,
    1975px 849px #fff;
  opacity: 0.35;
}
.stars:after {
  content: " ";
  top: -600px;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  opacity: 0.35;
  box-shadow:
    469px 2215px #fff,
    158px 2107px #fff,
    1500px 1166px #fff,
    94px 54px #fff,
    1904px 102px #fff,
    1666px 2106px #fff,
    506px 610px #fff,
    87px 3px #fff,
    1447px 1045px #fff,
    955px 1703px #fff,
    1847px 358px #fff,
    1284px 329px #fff,
    1019px 1787px #fff,
    445px 2386px #fff,
    391px 1318px #fff,
    613px 988px #fff,
    2523px 2007px #fff,
    2523px 396px #fff,
    1768px 1546px #fff,
    2005px 2414px #fff,
    2073px 770px #fff,
    1823px 28px #fff,
    594px 2094px #fff,
    1204px 1407px #fff,
    1812px 591px #fff,
    246px 292px #fff,
    898px 1913px #fff,
    1949px 841px #fff,
    652px 2429px #fff,
    1130px 2181px #fff,
    226px 2033px #fff,
    506px 1138px #fff,
    2106px 351px #fff,
    1461px 320px #fff,
    2560px 2292px #fff,
    511px 969px #fff,
    2151px 2020px #fff,
    601px 1673px #fff,
    840px 964px #fff,
    864px 1801px #fff,
    2438px 2131px #fff,
    669px 1668px #fff,
    1440px 2473px #fff,
    978px 2116px #fff,
    2372px 1550px #fff,
    1891px 903px #fff,
    259px 1344px #fff,
    1928px 1271px #fff,
    621px 1090px #fff,
    1524px 1332px #fff,
    1631px 1201px #fff,
    2295px 260px #fff,
    1641px 1806px #fff,
    670px 415px #fff,
    248px 2014px #fff,
    2088px 413px #fff,
    504px 1690px #fff,
    1886px 1547px #fff,
    398px 1232px #fff,
    13px 736px #fff,
    408px 966px #fff,
    1053px 1329px #fff,
    2131px 46px #fff,
    1117px 425px #fff,
    838px 479px #fff,
    2548px 782px #fff,
    432px 972px #fff,
    1899px 2332px #fff,
    369px 558px #fff,
    2201px 533px #fff,
    1110px 1178px #fff,
    1873px 78px #fff,
    1231px 630px #fff,
    702px 330px #fff,
    2270px 1097px #fff,
    1341px 2288px #fff,
    2311px 2233px #fff,
    1141px 2082px #fff,
    1249px 2415px #fff,
    873px 1203px #fff,
    394px 514px #fff,
    1374px 344px #fff,
    1948px 1195px #fff,
    2131px 2507px #fff,
    2475px 1815px #fff,
    1179px 2053px #fff,
    223px 295px #fff,
    669px 748px #fff,
    472px 477px #fff,
    705px 1116px #fff,
    62px 1191px #fff,
    939px 1664px #fff,
    529px 127px #fff,
    1239px 2530px #fff,
    1797px 441px #fff,
    1303px 1558px #fff,
    2457px 1301px #fff,
    2257px 1925px #fff,
    2482px 1473px #fff,
    73px 1554px #fff,
    15px 101px #fff,
    1975px 2170px #fff,
    687px 1298px #fff,
    714px 1558px #fff,
    236px 1511px #fff,
    2494px 1307px #fff,
    84px 545px #fff,
    795px 1633px #fff,
    1134px 179px #fff,
    748px 2092px #fff,
    781px 1569px #fff,
    1862px 2106px #fff,
    1757px 677px #fff,
    1586px 687px #fff,
    1926px 2501px #fff,
    400px 1901px #fff,
    118px 1433px #fff,
    524px 719px #fff,
    1447px 347px #fff,
    1447px 1271px #fff,
    1166px 456px #fff,
    1419px 1671px #fff,
    1884px 4px #fff,
    829px 2273px #fff,
    603px 2429px #fff,
    1547px 278px #fff,
    340px 75px #fff,
    2082px 648px #fff,
    525px 332px #fff,
    2177px 1412px #fff,
    2286px 825px #fff,
    538px 1790px #fff,
    652px 1768px #fff,
    277px 2247px #fff,
    1806px 2326px #fff,
    85px 2219px #fff,
    74px 1030px #fff,
    1458px 88px #fff,
    1965px 828px #fff,
    1395px 502px #fff,
    2468px 2151px #fff,
    2342px 287px #fff,
    1476px 432px #fff,
    640px 2055px #fff,
    423px 247px #fff,
    1595px 2432px #fff,
    245px 1141px #fff,
    694px 608px #fff,
    1215px 2071px #fff,
    948px 2465px #fff,
    2124px 2356px #fff,
    1757px 226px #fff,
    1762px 647px #fff,
    1706px 2265px #fff,
    1278px 786px #fff,
    1312px 1023px #fff,
    1741px 116px #fff,
    1278px 879px #fff,
    840px 466px #fff,
    1160px 2518px #fff,
    666px 374px #fff,
    2386px 2052px #fff,
    557px 2559px #fff,
    2210px 791px #fff,
    2303px 385px #fff,
    2475px 2218px #fff,
    1934px 1700px #fff,
    1482px 2129px #fff,
    858px 1547px #fff,
    112px 856px #fff,
    1472px 1732px #fff,
    1238px 1083px #fff,
    800px 2349px #fff,
    367px 1732px #fff,
    2378px 1224px #fff,
    1317px 2511px #fff,
    1403px 91px #fff,
    467px 787px #fff,
    1900px 2525px #fff,
    1603px 720px #fff,
    2365px 96px #fff,
    1959px 1780px #fff,
    2392px 537px #fff,
    1059px 471px #fff,
    2055px 622px #fff,
    2134px 659px #fff,
    1173px 142px #fff,
    1291px 1427px #fff,
    2357px 349px #fff,
    1353px 2489px #fff,
    2240px 1753px #fff,
    1971px 2249px #fff,
    2442px 1564px #fff,
    829px 1324px #fff,
    1548px 650px #fff,
    92px 976px #fff,
    2425px 614px #fff,
    1600px 1363px #fff,
    773px 2062px #fff,
    1162px 1605px #fff,
    2534px 1826px #fff,
    1436px 277px #fff,
    1225px 1284px #fff,
    422px 1713px #fff,
    2322px 173px #fff,
    658px 2181px #fff,
    785px 1607px #fff,
    1667px 1213px #fff,
    544px 2400px #fff,
    164px 1655px #fff,
    1949px 974px #fff,
    2145px 405px #fff,
    465px 2489px #fff,
    877px 2027px #fff,
    597px 1681px #fff,
    1580px 1238px #fff,
    164px 1639px #fff,
    1695px 33px #fff,
    2549px 1185px #fff,
    2352px 300px #fff,
    8px 1847px #fff,
    370px 895px #fff,
    557px 265px #fff,
    2400px 1992px #fff,
    20px 974px #fff,
    329px 2114px #fff,
    1587px 395px #fff,
    288px 1456px #fff,
    2408px 729px #fff,
    614px 1086px #fff,
    99px 1699px #fff,
    1159px 1311px #fff,
    1570px 1075px #fff,
    1244px 1671px #fff,
    2549px 704px #fff,
    1400px 1457px #fff,
    402px 1415px #fff,
    2522px 1366px #fff,
    485px 283px #fff,
    2502px 1204px #fff,
    2074px 1861px #fff,
    2201px 1868px #fff,
    795px 2234px #fff,
    2304px 2137px #fff,
    2489px 1406px #fff,
    277px 2301px #fff,
    1775px 779px #fff,
    1127px 674px #fff,
    12px 2155px #fff,
    951px 1775px #fff,
    1236px 1764px #fff,
    396px 103px #fff,
    2102px 169px #fff,
    160px 2013px #fff,
    2514px 483px #fff,
    1755px 2158px #fff,
    1284px 2087px #fff,
    1528px 1005px #fff,
    940px 1290px #fff,
    2461px 2504px #fff,
    684px 1001px #fff,
    603px 1777px #fff,
    92px 670px #fff,
    743px 1437px #fff,
    1330px 43px #fff,
    1487px 1458px #fff,
    238px 2090px #fff,
    1435px 105px #fff,
    2039px 1249px #fff,
    476px 2160px #fff,
    384px 366px #fff,
    1291px 2043px #fff,
    21px 1238px #fff,
    300px 219px #fff,
    1516px 1754px #fff,
    1324px 1409px #fff,
    987px 2522px #fff,
    2430px 1272px #fff,
    1646px 1782px #fff,
    1560px 57px #fff,
    1702px 1366px #fff,
    1589px 1454px #fff,
    701px 1879px #fff,
    420px 1884px #fff,
    1755px 97px #fff,
    173px 1301px #fff,
    1945px 1522px #fff,
    804px 2465px #fff,
    556px 1480px #fff,
    1044px 917px #fff,
    47px 2350px #fff,
    21px 1285px #fff,
    2108px 2051px #fff,
    597px 1680px #fff,
    64px 1514px #fff,
    2255px 1583px #fff,
    342px 507px #fff,
    1540px 652px #fff,
    833px 174px #fff,
    459px 1187px #fff,
    2377px 1262px #fff,
    2231px 1554px #fff,
    787px 355px #fff,
    1558px 767px #fff,
    1754px 2239px #fff,
    1528px 2169px #fff,
    2205px 1976px #fff,
    1836px 422px #fff,
    2128px 1118px #fff,
    1403px 1980px #fff,
    2038px 1787px #fff,
    500px 1211px #fff,
    2559px 528px #fff,
    422px 830px #fff,
    1220px 1725px #fff,
    1038px 275px #fff,
    1432px 1803px #fff,
    1373px 1047px #fff,
    2084px 734px #fff,
    399px 2279px #fff,
    551px 2324px #fff,
    2511px 2349px #fff,
    1355px 1294px #fff,
    2224px 81px #fff,
    575px 1654px #fff,
    2288px 1111px #fff,
    441px 1373px #fff,
    548px 1261px #fff,
    936px 1558px #fff,
    1537px 1069px #fff,
    2441px 490px #fff,
    392px 445px #fff,
    2481px 198px #fff,
    2163px 1943px #fff,
    1352px 2120px #fff,
    2031px 278px #fff,
    691px 233px #fff,
    816px 1915px #fff,
    1445px 1527px #fff,
    932px 1101px #fff,
    1953px 1586px #fff,
    433px 897px #fff,
    1766px 1576px #fff,
    1778px 751px #fff,
    1250px 2077px #fff,
    570px 658px #fff,
    1052px 1416px #fff,
    737px 5px #fff,
    1573px 2389px #fff,
    643px 162px #fff,
    1050px 2019px #fff,
    1295px 2514px #fff,
    1155px 1681px #fff,
    783px 984px #fff,
    2225px 1811px #fff,
    188px 1037px #fff,
    1160px 922px #fff,
    26px 1976px #fff,
    2368px 953px #fff,
    1274px 131px #fff,
    900px 473px #fff,
    84px 1918px #fff,
    1247px 1139px #fff,
    1657px 1493px #fff,
    1166px 1669px #fff,
    497px 1355px #fff,
    1934px 348px #fff,
    1891px 981px #fff,
    1776px 1139px #fff,
    2217px 4px #fff,
    1652px 2032px #fff,
    2478px 289px #fff,
    2403px 1737px #fff,
    1306px 2239px #fff,
    402px 1007px #fff,
    2516px 2091px #fff,
    624px 1665px #fff,
    999px 1135px #fff,
    568px 629px #fff,
    26px 2392px #fff,
    380px 155px #fff,
    1375px 1202px #fff,
    7px 1470px #fff,
    311px 2206px #fff,
    20px 1036px #fff,
    783px 2149px #fff,
    1796px 2078px #fff,
    2514px 118px #fff,
    570px 1221px #fff,
    999px 2547px #fff,
    1243px 368px #fff,
    2094px 1235px #fff,
    966px 473px #fff,
    1233px 574px #fff,
    759px 1461px #fff,
    1136px 1276px #fff,
    241px 2202px #fff,
    1986px 2036px #fff,
    1448px 742px #fff,
    1472px 410px #fff,
    1237px 1187px #fff,
    2362px 2323px #fff,
    409px 714px #fff,
    373px 1350px #fff,
    2177px 1432px #fff,
    680px 1943px #fff,
    406px 1208px #fff,
    453px 330px #fff,
    2162px 942px #fff,
    2227px 2420px #fff,
    604px 1556px #fff,
    1053px 914px #fff,
    582px 2410px #fff,
    2381px 2406px #fff,
    1276px 1750px #fff,
    45px 320px #fff,
    2364px 846px #fff,
    962px 2524px #fff,
    1851px 620px #fff,
    1442px 202px #fff,
    934px 399px #fff,
    262px 128px #fff,
    1427px 203px #fff,
    2203px 356px #fff,
    832px 108px #fff,
    272px 1053px #fff,
    499px 2001px #fff,
    2243px 673px #fff,
    1212px 2375px #fff,
    229px 1322px #fff,
    1916px 1922px #fff,
    2038px 1106px #fff,
    359px 2422px #fff,
    2288px 448px #fff,
    2512px 2206px #fff,
    1938px 1737px #fff,
    1425px 1949px #fff,
    2471px 708px #fff,
    2383px 921px #fff,
    191px 1694px #fff,
    808px 1069px #fff,
    2509px 1672px #fff,
    2089px 618px #fff,
    419px 2220px #fff,
    2413px 975px #fff,
    2355px 2215px #fff,
    1310px 1219px #fff,
    2389px 147px #fff,
    2270px 1641px #fff,
    1283px 1159px #fff,
    2409px 34px #fff,
    524px 2146px #fff,
    2316px 202px #fff,
    2149px 73px #fff,
    502px 2243px #fff,
    1787px 799px #fff,
    362px 217px #fff,
    2498px 1688px #fff,
    2168px 2266px #fff,
    1445px 1361px #fff,
    900px 80px #fff,
    52px 1230px #fff,
    761px 1902px #fff,
    1987px 565px #fff,
    1031px 1078px #fff,
    446px 525px #fff,
    247px 727px #fff,
    141px 271px #fff,
    2069px 996px #fff,
    1038px 1583px #fff,
    2067px 1011px #fff,
    1905px 1057px #fff,
    918px 1105px #fff,
    692px 1840px #fff,
    664px 1096px #fff,
    2383px 263px #fff,
    1902px 2530px #fff,
    1282px 2012px #fff,
    614px 2423px #fff,
    1327px 1613px #fff,
    1198px 1107px #fff,
    457px 640px #fff,
    1419px 1175px #fff,
    1247px 557px #fff,
    241px 94px #fff,
    1424px 948px #fff,
    2441px 1633px #fff,
    1659px 298px #fff,
    2196px 733px #fff,
    2033px 459px #fff,
    345px 521px #fff,
    571px 1065px #fff,
    1211px 2183px #fff,
    2393px 1706px #fff,
    531px 568px #fff,
    305px 2139px #fff,
    1834px 875px #fff,
    2032px 1214px #fff,
    555px 2472px #fff,
    2194px 283px #fff,
    383px 2364px #fff,
    2091px 2437px #fff,
    943px 117px #fff,
    1729px 631px #fff,
    560px 2269px #fff,
    1274px 2541px #fff,
    1092px 253px #fff,
    633px 1749px #fff,
    1617px 2149px #fff,
    2038px 1840px #fff,
    274px 1928px #fff,
    2204px 1158px #fff,
    2450px 2269px #fff,
    2205px 2266px #fff,
    724px 868px #fff,
    1327px 1565px #fff,
    1190px 2034px #fff,
    978px 365px #fff,
    1666px 38px #fff,
    1577px 353px #fff,
    979px 1037px #fff,
    1500px 1573px #fff,
    1471px 1283px #fff,
    577px 491px #fff,
    535px 707px #fff,
    1869px 79px #fff,
    1647px 976px #fff,
    913px 2196px #fff,
    1554px 1799px #fff,
    1677px 428px #fff,
    1891px 1360px #fff,
    1633px 241px #fff,
    1085px 2207px #fff,
    957px 986px #fff,
    1805px 1228px #fff,
    2262px 1278px #fff,
    45px 1373px #fff,
    2064px 1906px #fff,
    2236px 791px #fff,
    1069px 2098px #fff,
    133px 245px #fff,
    1460px 908px #fff,
    2252px 1464px #fff,
    1434px 1462px #fff,
    2117px 644px #fff,
    1478px 1403px #fff,
    552px 642px #fff,
    2432px 997px #fff,
    1347px 503px #fff,
    1661px 2366px #fff,
    810px 376px #fff,
    1789px 2328px #fff,
    1934px 67px #fff,
    135px 1996px #fff,
    1663px 1341px #fff,
    1346px 1439px #fff,
    529px 844px #fff,
    2315px 735px #fff,
    2450px 2055px #fff,
    756px 873px #fff,
    2193px 2332px #fff,
    1645px 9px #fff,
    1929px 594px #fff,
    705px 1697px #fff,
    1741px 981px #fff,
    1727px 1987px #fff,
    1634px 432px #fff,
    93px 1261px #fff,
    845px 681px #fff,
    751px 896px #fff,
    2148px 421px #fff,
    518px 2277px #fff,
    2196px 2498px #fff,
    1230px 1749px #fff,
    226px 774px #fff,
    2372px 1066px #fff,
    223px 2145px #fff,
    2415px 253px #fff,
    2319px 2510px #fff,
    1620px 1207px #fff,
    1286px 1641px #fff,
    2334px 1590px #fff,
    1646px 536px #fff,
    1423px 812px #fff,
    915px 999px #fff,
    203px 408px #fff,
    2125px 438px #fff,
    310px 1215px #fff,
    2334px 306px #fff,
    279px 2419px #fff,
    1590px 2153px #fff,
    18px 1678px #fff,
    2225px 307px #fff,
    847px 1505px #fff,
    924px 1750px #fff,
    547px 1288px #fff,
    2027px 1989px #fff,
    268px 1194px #fff,
    2001px 2109px #fff,
    2128px 8px #fff,
    901px 2411px #fff,
    476px 1165px #fff,
    2526px 455px #fff,
    570px 429px #fff,
    632px 255px #fff,
    2490px 2341px #fff,
    2209px 1196px #fff,
    1837px 900px #fff,
    152px 2557px #fff,
    1178px 1454px #fff,
    579px 2388px #fff,
    2229px 2083px #fff,
    2108px 1713px #fff,
    2187px 503px #fff,
    2398px 1018px #fff,
    1783px 246px #fff,
    1844px 2014px #fff,
    420px 1976px #fff,
    1679px 1098px #fff,
    2189px 741px #fff,
    1767px 2478px #fff,
    1075px 1089px #fff,
    17px 707px #fff,
    1917px 1999px #fff,
    1293px 986px #fff,
    2089px 83px #fff,
    2016px 145px #fff,
    804px 1795px #fff,
    2495px 208px #fff,
    788px 251px #fff,
    599px 1158px #fff,
    2514px 1081px #fff,
    1568px 325px #fff,
    2061px 1035px #fff,
    1622px 1926px #fff,
    14px 973px #fff,
    1982px 716px #fff,
    2510px 1957px #fff,
    2134px 2349px #fff,
    674px 379px #fff,
    699px 219px #fff,
    295px 1682px #fff,
    20px 204px #fff,
    1508px 1342px #fff,
    225px 1799px #fff,
    543px 7px #fff,
    164px 2405px #fff,
    1403px 390px #fff,
    1180px 2103px #fff,
    1079px 637px #fff,
    450px 2419px #fff,
    1553px 1637px #fff,
    2016px 777px #fff,
    106px 1332px #fff,
    1461px 1071px #fff,
    73px 2051px #fff,
    1051px 2378px #fff,
    1557px 1620px #fff,
    8px 915px #fff,
    1505px 1069px #fff,
    57px 2421px #fff,
    522px 2065px #fff,
    826px 871px #fff,
    1328px 1159px #fff,
    1712px 1294px #fff,
    2082px 2259px #fff,
    2512px 2046px #fff,
    1314px 151px #fff,
    1718px 732px #fff,
    2183px 1790px #fff,
    834px 718px #fff,
    1602px 2348px #fff,
    928px 995px #fff,
    386px 1128px #fff,
    1905px 1341px #fff,
    952px 286px #fff,
    875px 32px #fff,
    1817px 592px #fff,
    1381px 1760px #fff,
    1358px 1264px #fff,
    1041px 1113px #fff,
    1158px 1970px #fff,
    1837px 881px #fff,
    1652px 2396px #fff,
    650px 2039px #fff,
    2354px 2025px #fff,
    1878px 987px #fff,
    1123px 551px #fff,
    2084px 2542px #fff,
    2224px 1215px #fff,
    96px 2300px #fff,
    270px 2302px #fff,
    1582px 662px #fff,
    145px 962px #fff,
    2502px 263px #fff,
    2183px 1084px #fff,
    1536px 2213px #fff,
    404px 2473px #fff,
    284px 2155px #fff,
    941px 1516px #fff,
    1273px 1740px #fff,
    2081px 92px #fff,
    2158px 1580px #fff,
    2487px 979px #fff,
    1126px 1107px #fff,
    2225px 436px #fff,
    1087px 2534px #fff,
    138px 238px #fff,
    1176px 172px #fff,
    1933px 2288px #fff,
    800px 2343px #fff,
    1632px 81px #fff,
    651px 1746px #fff,
    982px 727px #fff,
    473px 2254px #fff,
    1431px 1033px #fff,
    1213px 1093px #fff,
    707px 1280px #fff,
    1555px 2366px #fff,
    2215px 343px #fff,
    204px 797px #fff,
    971px 1429px #fff,
    1984px 1570px #fff,
    1854px 2313px #fff,
    866px 1836px #fff,
    1350px 1857px #fff,
    1258px 1706px #fff,
    1172px 634px #fff,
    1234px 1710px #fff,
    1857px 547px #fff,
    1046px 1052px #fff,
    221px 1461px #fff,
    1915px 828px #fff,
    543px 1461px #fff,
    2408px 1615px #fff,
    2283px 1979px #fff,
    421px 724px #fff,
    134px 2024px #fff,
    245px 1736px #fff,
    1473px 2160px #fff,
    1662px 778px #fff,
    1059px 854px #fff,
    434px 362px #fff,
    811px 675px #fff,
    777px 1406px #fff,
    2352px 410px #fff,
    1729px 1855px #fff,
    1276px 1072px #fff,
    292px 734px #fff,
    2318px 540px #fff,
    36px 725px #fff,
    870px 1535px #fff,
    545px 1478px #fff,
    1601px 295px #fff,
    643px 2090px #fff,
    547px 939px #fff,
    553px 411px #fff,
    1220px 1987px #fff,
    745px 2541px #fff,
    778px 220px #fff,
    2116px 1313px #fff,
    1638px 1397px #fff,
    697px 369px #fff,
    513px 1357px #fff,
    1033px 1753px #fff,
    572px 875px #fff,
    461px 280px #fff,
    812px 226px #fff,
    288px 1906px #fff,
    10px 94px #fff,
    643px 556px #fff,
    871px 1339px #fff,
    812px 157px #fff,
    2179px 1071px #fff,
    787px 2127px #fff,
    900px 488px #fff,
    1002px 1425px #fff,
    2441px 1879px #fff,
    1846px 1020px #fff,
    2138px 349px #fff,
    1367px 1910px #fff,
    286px 295px #fff,
    1104px 2364px #fff,
    2227px 149px #fff,
    884px 176px #fff,
    2160px 1598px #fff,
    1566px 686px #fff,
    711px 119px #fff,
    1367px 1067px #fff,
    641px 2285px #fff,
    1605px 389px #fff,
    1324px 608px #fff,
    2434px 1232px #fff,
    229px 691px #fff,
    2065px 720px #fff,
    2289px 822px #fff,
    1160px 1375px #fff,
    450px 1948px #fff,
    1325px 99px #fff,
    2192px 102px #fff,
    1231px 281px #fff,
    1009px 699px #fff,
    540px 1415px #fff,
    375px 676px #fff,
    1236px 2072px #fff,
    1332px 1045px #fff,
    2px 1200px #fff,
    1928px 2459px #fff,
    2256px 770px #fff,
    884px 1981px #fff,
    2362px 259px #fff,
    916px 209px #fff,
    546px 1898px #fff,
    2353px 647px #fff,
    1466px 1880px #fff,
    1819px 755px #fff,
    602px 963px #fff,
    1343px 2356px #fff,
    1487px 1214px #fff,
    480px 406px #fff,
    2420px 125px #fff,
    2243px 2301px #fff,
    668px 208px #fff,
    1321px 1054px #fff,
    328px 851px #fff,
    2209px 1593px #fff,
    985px 2004px #fff,
    1936px 588px #fff,
    587px 159px #fff,
    1250px 1074px #fff,
    884px 442px #fff,
    164px 2131px #fff,
    2493px 2105px #fff,
    1672px 130px #fff,
    843px 2511px #fff,
    83px 2023px #fff,
    1298px 241px #fff,
    1117px 1363px #fff,
    2284px 245px #fff,
    1514px 2133px #fff,
    2020px 1260px #fff,
    488px 1655px #fff,
    1406px 1876px #fff,
    1886px 2433px #fff,
    456px 1262px #fff,
    1706px 1901px #fff,
    1881px 201px #fff,
    107px 2455px #fff,
    2493px 1156px #fff,
    1461px 533px #fff,
    494px 114px #fff,
    288px 612px #fff,
    311px 318px #fff,
    443px 1589px #fff,
    1061px 616px #fff,
    400px 183px #fff,
    2288px 2491px #fff,
    31px 2510px #fff,
    157px 1219px #fff,
    1155px 2101px #fff,
    1149px 1495px #fff,
    1012px 2440px #fff,
    670px 2215px #fff,
    1188px 230px #fff,
    519px 416px #fff,
    406px 1230px #fff,
    1835px 1034px #fff,
    2173px 1256px #fff,
    1291px 1666px #fff,
    2233px 1824px #fff,
    2002px 838px #fff,
    2401px 1171px #fff,
    1074px 151px #fff,
    887px 1849px #fff,
    1992px 2428px #fff,
    105px 1343px #fff,
    21px 2320px #fff,
    1914px 521px #fff,
    996px 758px #fff,
    978px 1627px #fff,
    2368px 1262px #fff,
    1009px 46px #fff,
    1120px 844px #fff,
    22px 252px #fff,
    1274px 1348px #fff,
    1151px 748px #fff,
    838px 1526px #fff,
    2145px 779px #fff,
    1986px 440px #fff,
    1243px 316px #fff,
    286px 1477px #fff,
    675px 940px #fff,
    1639px 1309px #fff,
    2027px 896px #fff,
    1208px 1395px #fff,
    622px 1900px #fff,
    2394px 1899px #fff,
    1877px 344px #fff,
    294px 1447px #fff,
    2029px 2172px #fff,
    1779px 2334px #fff,
    165px 1836px #fff,
    2113px 1507px #fff,
    814px 1708px #fff,
    713px 1372px #fff,
    860px 1413px #fff,
    1070px 488px #fff,
    733px 1464px #fff,
    1774px 1343px #fff,
    298px 958px #fff,
    2196px 1457px #fff,
    295px 2076px #fff,
    1782px 821px #fff,
    1008px 843px #fff,
    2127px 28px #fff,
    2239px 603px #fff,
    1279px 574px #fff,
    1703px 2274px #fff,
    1401px 746px #fff,
    1246px 802px #fff,
    1648px 687px #fff,
    1377px 1818px #fff,
    847px 1457px #fff,
    2077px 2302px #fff,
    779px 1854px #fff,
    2317px 881px #fff,
    1457px 2249px #fff,
    316px 1015px #fff,
    12px 1615px #fff,
    2244px 2228px #fff,
    602px 1537px #fff,
    115px 1690px #fff,
    2289px 1455px #fff,
    248px 389px #fff,
    1920px 2367px #fff,
    420px 2034px #fff,
    496px 975px #fff,
    2422px 767px #fff,
    298px 463px #fff,
    2068px 735px #fff,
    2264px 779px #fff,
    2391px 1993px #fff,
    2481px 1812px #fff,
    1537px 2215px #fff,
    1817px 1051px #fff,
    1703px 2072px #fff,
    2153px 1473px #fff,
    2432px 2166px #fff,
    2319px 2327px #fff,
    645px 1379px #fff,
    2337px 169px #fff,
    312px 1282px #fff,
    1268px 106px #fff,
    1105px 1222px #fff,
    365px 1px #fff,
    1007px 1037px #fff,
    1478px 966px #fff,
    2501px 817px #fff,
    2069px 867px #fff,
    2199px 1125px #fff,
    2110px 1519px #fff,
    1611px 109px #fff,
    316px 2352px #fff,
    1711px 2076px #fff,
    367px 2475px #fff,
    1384px 1938px #fff,
    1151px 1759px #fff,
    1813px 1549px #fff,
    158px 2509px #fff,
    1374px 78px #fff,
    2445px 480px #fff,
    2150px 115px #fff,
    575px 1238px #fff,
    336px 1432px #fff,
    207px 1236px #fff,
    1637px 1503px #fff,
    220px 1058px #fff,
    2246px 24px #fff,
    549px 982px #fff,
    179px 2072px #fff,
    893px 1024px #fff,
    2504px 618px #fff,
    184px 540px #fff,
    825px 1948px #fff,
    1px 1640px #fff,
    341px 1821px #fff,
    2179px 165px #fff,
    497px 2332px #fff,
    73px 2049px #fff,
    94px 509px #fff,
    1703px 158px #fff,
    2479px 326px #fff,
    2407px 1987px #fff,
    145px 2381px #fff,
    2454px 179px #fff,
    2293px 359px #fff,
    1980px 210px #fff,
    1564px 2374px #fff,
    1934px 1385px #fff,
    1224px 588px #fff,
    84px 1937px #fff,
    205px 155px #fff,
    1569px 2135px #fff,
    2140px 571px #fff,
    1698px 882px #fff,
    1353px 2022px #fff,
    2316px 1059px #fff,
    1546px 345px #fff,
    2484px 1505px #fff,
    1909px 1149px #fff,
    872px 2292px #fff,
    89px 1579px #fff,
    1675px 1383px #fff,
    847px 191px #fff,
    1373px 290px #fff,
    2110px 149px #fff,
    1133px 2065px #fff,
    970px 1433px #fff,
    216px 2420px #fff,
    449px 2314px #fff,
    1902px 230px #fff,
    1095px 2294px #fff,
    2298px 1603px #fff,
    2063px 328px #fff,
    1491px 1539px #fff,
    1295px 490px #fff,
    2134px 1157px #fff,
    1311px 590px #fff,
    316px 292px #fff,
    760px 2167px #fff,
    76px 1583px #fff,
    395px 849px #fff,
    811px 2525px #fff,
    1840px 2317px #fff,
    1994px 412px #fff,
    1346px 458px #fff,
    499px 16px #fff,
    384px 2013px #fff,
    308px 1162px #fff,
    1235px 435px #fff,
    202px 720px #fff,
    113px 2341px #fff,
    2255px 1574px #fff,
    1110px 921px #fff,
    1315px 258px #fff,
    781px 1692px #fff,
    1181px 1922px #fff,
    1660px 1207px #fff,
    1617px 2066px #fff,
    1278px 2292px #fff,
    156px 2461px #fff,
    345px 1682px #fff,
    1625px 1167px #fff,
    580px 401px #fff,
    1684px 1574px #fff,
    2389px 280px #fff,
    1549px 48px #fff,
    1476px 134px #fff,
    1996px 1224px #fff,
    718px 1519px #fff,
    127px 1052px #fff,
    1178px 1589px #fff,
    1246px 1846px #fff,
    498px 2059px #fff,
    2406px 427px #fff,
    55px 328px #fff,
    2459px 1246px #fff,
    646px 407px #fff,
    2417px 1179px #fff,
    956px 405px #fff,
    464px 985px #fff,
    2055px 678px #fff,
    1678px 2331px #fff,
    1832px 739px #fff,
    511px 1146px #fff,
    538px 482px #fff,
    2068px 2093px #fff,
    1694px 2413px #fff,
    260px 2182px #fff,
    865px 2004px #fff,
    955px 1127px #fff,
    825px 340px #fff,
    1178px 192px #fff,
    2308px 1998px #fff,
    1265px 167px #fff,
    1671px 368px #fff,
    132px 156px #fff,
    2509px 381px #fff,
    1953px 1665px #fff,
    482px 1190px #fff,
    1726px 1911px #fff,
    148px 843px #fff,
    204px 51px #fff,
    286px 2403px #fff,
    1776px 397px #fff,
    1715px 2309px #fff,
    2414px 2531px #fff,
    113px 220px #fff,
    1709px 477px #fff,
    2300px 1245px #fff,
    838px 185px #fff,
    2097px 1455px #fff,
    636px 744px #fff,
    1840px 1304px #fff,
    2323px 2378px #fff,
    454px 1248px #fff,
    971px 376px #fff,
    396px 1897px #fff,
    667px 1994px #fff,
    1012px 117px #fff,
    1364px 25px #fff,
    461px 2097px #fff,
    1146px 1710px #fff,
    1201px 1293px #fff,
    2210px 1484px #fff,
    1720px 1505px #fff,
    453px 355px #fff,
    1434px 1599px #fff,
    1535px 377px #fff,
    756px 299px #fff,
    2195px 1191px #fff,
    252px 493px #fff,
    1284px 2486px #fff,
    416px 1727px #fff,
    1750px 946px #fff,
    1647px 1777px #fff,
    2403px 2428px #fff,
    689px 2135px #fff,
    2232px 1706px #fff,
    1335px 2412px #fff,
    208px 1752px #fff,
    1994px 1187px #fff,
    1322px 2165px #fff,
    1526px 1929px #fff,
    591px 2350px #fff,
    1349px 181px #fff,
    795px 314px #fff,
    840px 398px #fff,
    265px 2087px #fff,
    2269px 1976px #fff,
    1476px 2235px #fff,
    1791px 366px #fff,
    294px 536px #fff,
    2194px 1509px #fff,
    21px 1163px #fff,
    2319px 810px #fff,
    1531px 1621px #fff,
    1572px 1721px #fff,
    1889px 1862px #fff,
    1458px 2220px #fff,
    1281px 2457px #fff,
    2031px 219px #fff,
    1439px 2328px #fff,
    2310px 1209px #fff,
    911px 514px #fff,
    2044px 1491px #fff,
    1200px 1361px #fff,
    1655px 2430px #fff,
    839px 216px #fff,
    1096px 1876px #fff,
    1202px 2242px #fff,
    2543px 2469px #fff,
    562px 281px #fff,
    94px 547px #fff,
    69px 336px #fff,
    460px 218px #fff,
    1583px 1134px #fff,
    1392px 1869px #fff,
    335px 2528px #fff,
    2205px 2289px #fff,
    193px 1584px #fff,
    111px 758px #fff,
    189px 39px #fff,
    274px 289px #fff,
    543px 1512px #fff,
    2289px 717px #fff,
    1151px 782px #fff,
    1134px 2109px #fff,
    252px 1626px #fff,
    2079px 867px #fff,
    330px 2027px #fff,
    1541px 737px #fff,
    1653px 1862px #fff,
    711px 816px #fff,
    1450px 957px #fff,
    1423px 175px #fff,
    1424px 1564px #fff,
    2126px 789px #fff,
    2155px 704px #fff,
    1541px 2183px #fff,
    2505px 933px #fff,
    631px 2190px #fff,
    2516px 2165px #fff,
    611px 273px #fff,
    2311px 1945px #fff,
    601px 960px #fff,
    1552px 1486px #fff,
    786px 1455px #fff,
    860px 2547px #fff,
    1244px 2200px #fff,
    58px 2506px #fff,
    2436px 2255px #fff,
    251px 169px #fff,
    208px 6px #fff,
    119px 574px #fff,
    2372px 828px #fff,
    878px 1166px #fff,
    1402px 2344px #fff,
    785px 2078px #fff,
    2240px 1088px #fff,
    2038px 2040px #fff,
    312px 2082px #fff,
    1545px 188px #fff,
    2402px 1595px #fff,
    305px 903px #fff,
    125px 679px #fff,
    5px 691px #fff,
    1477px 354px #fff,
    673px 756px #fff,
    1315px 593px #fff,
    680px 1082px #fff,
    2144px 2450px #fff,
    1151px 1677px #fff,
    1568px 1628px #fff,
    2404px 1495px #fff,
    639px 1358px #fff,
    1730px 2486px #fff,
    2340px 633px #fff,
    1888px 879px #fff,
    1874px 1512px #fff,
    1125px 1273px #fff,
    1707px 2300px #fff,
    928px 396px #fff,
    592px 528px #fff,
    2194px 1637px #fff,
    2px 594px #fff,
    1453px 2392px #fff,
    1848px 1140px #fff,
    1228px 559px #fff,
    1059px 2431px #fff,
    114px 2239px #fff,
    189px 1987px #fff,
    1724px 2178px #fff,
    2035px 1049px #fff,
    1941px 1118px #fff,
    2543px 916px #fff,
    29px 744px #fff,
    721px 1764px #fff,
    388px 830px #fff,
    2491px 1267px #fff,
    324px 658px #fff,
    792px 2070px #fff,
    1633px 2249px #fff,
    2071px 1324px #fff,
    1063px 622px #fff,
    2259px 1086px #fff,
    1432px 508px #fff,
    2346px 1627px #fff,
    1727px 289px #fff,
    1264px 518px #fff,
    1522px 1952px #fff,
    2171px 1652px #fff,
    1218px 1458px #fff,
    2226px 1015px #fff,
    2215px 1078px #fff,
    667px 2024px #fff,
    988px 870px #fff,
    670px 2282px #fff,
    968px 179px #fff,
    2398px 459px #fff,
    1163px 179px #fff,
    1985px 72px #fff,
    1209px 2143px #fff,
    1703px 1942px #fff,
    90px 1726px #fff,
    1264px 1976px #fff,
    1275px 662px #fff,
    1836px 503px #fff,
    1297px 1874px #fff,
    1805px 428px #fff,
    1125px 2492px #fff,
    1955px 1715px #fff,
    2338px 664px #fff,
    791px 339px #fff,
    152px 1330px #fff,
    1906px 986px #fff,
    194px 1729px #fff,
    219px 1973px #fff,
    206px 2252px #fff,
    1774px 1460px #fff,
    1320px 1402px #fff,
    1039px 1267px #fff,
    1228px 2547px #fff,
    1828px 170px #fff,
    1975px 1957px #fff,
    152px 2455px #fff,
    1564px 913px #fff,
    806px 1763px #fff,
    1672px 957px #fff,
    893px 532px #fff,
    2097px 927px #fff,
    2108px 5px #fff,
    1948px 1223px #fff,
    1455px 1857px #fff,
    1706px 987px #fff,
    1506px 571px #fff,
    1973px 2195px #fff,
    1086px 924px #fff,
    1706px 572px #fff,
    1606px 1020px #fff,
    939px 61px #fff,
    868px 1933px #fff,
    257px 2247px #fff,
    1141px 2313px #fff,
    1784px 1452px #fff,
    289px 900px #fff,
    777px 2521px #fff,
    2532px 35px #fff,
    1626px 1444px #fff,
    1659px 691px #fff,
    810px 735px #fff,
    1892px 2434px #fff,
    865px 557px #fff,
    1723px 2146px #fff,
    658px 212px #fff,
    66px 2044px #fff,
    1663px 2084px #fff,
    1332px 940px #fff,
    2361px 2281px #fff,
    319px 1035px #fff,
    1737px 172px #fff,
    1200px 447px #fff,
    1508px 384px #fff,
    525px 2282px #fff,
    2436px 108px #fff,
    1935px 1460px #fff,
    1911px 1827px #fff,
    7px 1860px #fff,
    1056px 1037px #fff,
    1981px 267px #fff,
    1150px 1145px #fff,
    777px 281px #fff,
    428px 2210px #fff,
    1762px 363px #fff,
    2002px 1130px #fff,
    1323px 653px #fff,
    814px 2086px #fff,
    45px 1187px #fff,
    308px 1046px #fff,
    609px 605px #fff,
    505px 1881px #fff,
    725px 1957px #fff,
    1548px 1617px #fff,
    1518px 1099px #fff,
    300px 2090px #fff,
    1637px 1004px #fff,
    104px 649px #fff,
    320px 1148px #fff,
    1922px 1209px #fff,
    469px 42px #fff,
    1363px 130px #fff,
    1888px 2347px #fff,
    2325px 1727px #fff,
    628px 1689px #fff,
    1663px 1143px #fff,
    373px 1770px #fff,
    578px 50px #fff,
    1580px 1572px #fff,
    47px 1363px #fff,
    948px 1441px #fff,
    1527px 1115px #fff,
    9px 1294px #fff,
    78px 317px #fff,
    490px 2560px #fff,
    1507px 722px #fff,
    491px 768px #fff,
    403px 904px #fff,
    155px 2274px #fff,
    1693px 709px #fff,
    202px 343px #fff,
    990px 1106px #fff,
    1137px 1226px #fff,
    336px 1867px #fff,
    1233px 750px #fff,
    64px 1231px #fff,
    369px 1708px #fff,
    541px 2118px #fff,
    2120px 2307px #fff,
    2531px 1286px #fff,
    1094px 1925px #fff,
    1994px 1909px #fff,
    324px 1629px #fff,
    1191px 1758px #fff,
    470px 1852px #fff,
    1986px 2122px #fff,
    2220px 1855px #fff,
    594px 794px #fff,
    1814px 661px #fff,
    1267px 2189px #fff,
    2215px 2402px #fff,
    1961px 1159px #fff,
    1280px 16px #fff,
    2123px 1623px #fff,
    1666px 1986px #fff,
    2267px 739px #fff,
    1992px 1764px #fff,
    138px 2113px #fff,
    1013px 1265px #fff,
    1405px 1431px #fff,
    818px 107px #fff,
    1696px 910px #fff,
    235px 76px #fff,
    2138px 1972px #fff,
    2511px 2012px #fff,
    2518px 2059px #fff,
    1209px 214px #fff,
    2207px 687px #fff,
    154px 1886px #fff,
    673px 751px #fff,
    5px 2431px #fff,
    2060px 2174px #fff,
    657px 1803px #fff,
    1566px 432px #fff,
    1745px 1273px #fff,
    340px 782px #fff,
    1018px 210px #fff,
    264px 2110px #fff,
    1312px 176px #fff,
    260px 2081px #fff,
    1393px 796px #fff,
    913px 479px #fff,
    216px 1896px #fff,
    2320px 682px #fff,
    1239px 951px #fff,
    2161px 1017px #fff,
    212px 386px #fff,
    140px 2142px #fff,
    2052px 191px #fff,
    534px 4px #fff,
    1557px 1029px #fff,
    2177px 1619px #fff,
    1315px 855px #fff,
    132px 2248px #fff,
    15px 1417px #fff,
    841px 775px #fff,
    1083px 680px #fff,
    2316px 786px #fff,
    2026px 902px #fff,
    433px 1541px #fff,
    274px 1401px #fff,
    450px 161px #fff,
    753px 2243px #fff,
    865px 1600px #fff,
    1164px 1964px #fff,
    2035px 430px #fff,
    2164px 1419px #fff,
    1970px 1435px #fff,
    802px 1967px #fff,
    1835px 245px #fff,
    8px 821px #fff,
    295px 2233px #fff,
    138px 2551px #fff,
    550px 30px #fff,
    481px 1221px #fff,
    623px 65px #fff,
    635px 1074px #fff,
    1510px 2373px #fff,
    1549px 1035px #fff,
    263px 671px #fff,
    2444px 1547px #fff,
    1835px 870px #fff,
    934px 2228px #fff,
    151px 1686px #fff,
    1711px 1774px #fff,
    2215px 1110px #fff,
    388px 2142px #fff,
    619px 2383px #fff,
    2495px 548px #fff,
    209px 2438px #fff,
    767px 28px #fff,
    1813px 278px #fff,
    455px 882px #fff,
    782px 2386px #fff,
    690px 1695px #fff,
    1494px 2101px #fff,
    33px 1154px #fff,
    2028px 1562px #fff,
    24px 910px #fff,
    745px 1864px #fff,
    2006px 2350px #fff,
    235px 868px #fff,
    627px 259px #fff,
    1516px 1572px #fff,
    2406px 1472px #fff,
    1158px 212px #fff,
    2481px 33px #fff,
    501px 685px #fff,
    2418px 1526px #fff,
    333px 2055px #fff,
    1278px 514px #fff,
    632px 425px #fff,
    26px 1497px #fff,
    2402px 502px #fff,
    1790px 2050px #fff,
    1989px 411px #fff,
    370px 2326px #fff,
    151px 602px #fff,
    148px 1278px #fff,
    51px 48px #fff,
    2240px 1024px #fff,
    2122px 1600px #fff,
    1108px 124px #fff,
    1490px 2151px #fff,
    2221px 724px #fff,
    1942px 2222px #fff,
    2081px 2180px #fff,
    311px 1492px #fff,
    1953px 1925px #fff,
    377px 314px #fff,
    2258px 273px #fff,
    157px 2497px #fff,
    500px 2274px #fff,
    1633px 2495px #fff,
    1930px 690px #fff,
    321px 1437px #fff,
    2340px 1233px #fff,
    2008px 1275px #fff,
    1353px 1646px #fff,
    420px 407px #fff,
    593px 428px #fff,
    1183px 35px #fff,
    1793px 1041px #fff,
    1278px 1538px #fff,
    1747px 472px #fff,
    1453px 911px #fff,
    787px 768px #fff,
    583px 756px #fff,
    1859px 14px #fff,
    273px 2548px #fff,
    2105px 858px #fff,
    2044px 2503px #fff,
    1015px 2230px #fff,
    2557px 1567px #fff,
    1022px 2342px #fff,
    964px 1797px #fff,
    880px 118px #fff,
    364px 2395px #fff,
    923px 1915px #fff,
    935px 350px #fff,
    480px 1647px #fff,
    61px 846px #fff,
    1523px 1503px #fff,
    2326px 2268px #fff,
    775px 929px #fff,
    1377px 669px #fff,
    1130px 1428px #fff,
    674px 2277px #fff,
    2336px 508px #fff,
    1460px 1061px #fff,
    1960px 2456px #fff,
    109px 2543px #fff,
    878px 1403px #fff,
    327px 1434px #fff,
    349px 1706px #fff,
    1917px 1655px #fff,
    2346px 622px #fff,
    723px 1512px #fff,
    5px 1412px #fff,
    1989px 2235px #fff,
    1131px 433px #fff,
    1000px 1893px #fff,
    410px 2362px #fff,
    1440px 506px #fff,
    1935px 839px #fff,
    1566px 513px #fff,
    602px 409px #fff,
    61px 275px #fff,
    1291px 583px #fff,
    1590px 2434px #fff,
    1118px 1135px #fff,
    1099px 1744px #fff,
    707px 1853px #fff,
    154px 1031px #fff,
    943px 1853px #fff,
    513px 437px #fff,
    1511px 60px #fff,
    2056px 2440px #fff,
    1755px 2407px #fff,
    254px 1390px #fff,
    2089px 1783px #fff,
    20px 385px #fff,
    253px 738px #fff,
    644px 1406px #fff,
    1245px 97px #fff,
    1843px 941px #fff,
    494px 2150px #fff,
    415px 783px #fff,
    2396px 603px #fff,
    1189px 1619px #fff,
    662px 740px #fff,
    1770px 57px #fff,
    1533px 1703px #fff,
    1358px 2550px #fff,
    2147px 657px #fff,
    1026px 1572px #fff,
    380px 2093px #fff,
    1967px 2135px #fff,
    1044px 1751px #fff,
    349px 1054px #fff,
    2022px 1124px #fff,
    901px 763px #fff,
    280px 2034px #fff,
    1265px 1244px #fff,
    909px 1326px #fff,
    1377px 279px #fff,
    1737px 1180px #fff,
    1637px 1084px #fff,
    2452px 667px #fff,
    584px 1733px #fff,
    621px 186px #fff,
    470px 1013px #fff,
    743px 1442px #fff,
    673px 1112px #fff,
    2031px 2262px #fff,
    319px 1783px #fff,
    186px 637px #fff,
    2161px 2207px #fff,
    976px 924px #fff,
    1950px 2159px #fff,
    813px 2038px #fff,
    2336px 1469px #fff,
    854px 2431px #fff,
    2337px 212px #fff,
    724px 2437px #fff,
    5px 1986px #fff,
    720px 1085px #fff,
    180px 1580px #fff,
    1721px 1496px #fff,
    504px 89px #fff,
    186px 1356px #fff,
    844px 717px #fff,
    755px 1677px #fff,
    332px 2037px #fff,
    1751px 1262px #fff,
    1309px 1482px #fff,
    1329px 1734px #fff,
    1632px 280px #fff,
    981px 1481px #fff,
    820px 1910px #fff,
    682px 2543px #fff,
    2052px 796px #fff,
    362px 1090px #fff,
    1530px 1353px #fff,
    1302px 1241px #fff,
    2527px 1046px #fff,
    645px 1569px #fff,
    1046px 2000px #fff,
    1043px 2174px #fff,
    2333px 1666px #fff,
    1790px 439px #fff,
    1284px 886px #fff,
    2345px 900px #fff,
    225px 486px #fff,
    942px 1844px #fff,
    703px 1222px #fff,
    2257px 439px #fff,
    724px 551px #fff,
    2349px 1771px #fff,
    2229px 1100px #fff,
    1018px 1498px #fff,
    279px 575px #fff,
    1517px 1949px #fff,
    1723px 1443px #fff,
    1347px 199px #fff,
    1548px 1739px #fff,
    2081px 1961px #fff,
    1535px 2360px #fff,
    2104px 1486px #fff,
    1821px 1074px #fff,
    641px 1482px #fff,
    2080px 2017px #fff,
    2378px 1428px #fff,
    1743px 2225px #fff,
    277px 1302px #fff,
    2294px 2497px #fff,
    1050px 2142px #fff,
    61px 1349px #fff,
    1272px 951px #fff,
    414px 2343px #fff,
    1153px 2212px #fff,
    2429px 69px #fff,
    1771px 1575px #fff,
    130px 2245px #fff,
    196px 419px #fff,
    1666px 1973px #fff,
    1684px 1902px #fff,
    876px 149px #fff,
    2056px 2507px #fff,
    25px 181px #fff,
    255px 146px #fff;
}

.stars1:after {
  content: " ";
  top: -600px;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  opacity: 0.25;
  box-shadow:
    1663px 1582px #fff,
    1514px 316px #fff,
    150px 2380px #fff,
    1391px 28px #fff,
    1303px 1034px #fff,
    2201px 1471px #fff,
    714px 2427px #fff,
    363px 2559px #fff,
    167px 56px #fff,
    2120px 1417px #fff,
    646px 1038px #fff,
    1940px 1491px #fff,
    1406px 456px #fff,
    1061px 1096px #fff,
    1606px 2044px #fff,
    518px 1415px #fff,
    610px 2424px #fff,
    691px 805px #fff,
    374px 1542px #fff,
    1764px 2339px #fff,
    917px 1964px #fff,
    501px 2328px #fff,
    389px 1517px #fff,
    647px 509px #fff,
    2214px 197px #fff,
    991px 288px #fff,
    2524px 20px #fff,
    577px 533px #fff,
    1985px 1505px #fff,
    1947px 780px #fff,
    71px 1390px #fff,
    2017px 792px #fff,
    833px 709px #fff,
    952px 2414px #fff,
    1191px 2230px #fff,
    387px 2243px #fff,
    1474px 2499px #fff,
    1455px 702px #fff,
    976px 1641px #fff,
    2211px 1296px #fff,
    989px 111px #fff,
    138px 455px #fff,
    439px 1161px #fff,
    1417px 1629px #fff,
    927px 126px #fff,
    2328px 1260px #fff,
    179px 1638px #fff,
    1065px 1748px #fff,
    1363px 2267px #fff,
    101px 558px #fff,
    413px 1510px #fff,
    62px 266px #fff,
    1172px 154px #fff,
    1908px 145px #fff,
    1208px 2017px #fff,
    440px 2223px #fff,
    1036px 1723px #fff,
    1568px 1097px #fff,
    483px 43px #fff,
    768px 872px #fff,
    273px 1061px #fff,
    786px 1232px #fff,
    1026px 1674px #fff,
    2194px 817px #fff,
    494px 33px #fff,
    176px 78px #fff,
    223px 1729px #fff,
    1903px 723px #fff,
    336px 128px #fff,
    1339px 339px #fff,
    1028px 1301px #fff,
    167px 450px #fff,
    2416px 117px #fff,
    1866px 1073px #fff,
    1797px 216px #fff,
    315px 252px #fff,
    2125px 2039px #fff,
    2205px 129px #fff,
    2022px 1238px #fff,
    1316px 2076px #fff,
    1977px 2037px #fff,
    1016px 1684px #fff,
    229px 2375px #fff,
    535px 348px #fff,
    2508px 2175px #fff,
    1577px 684px #fff,
    2283px 1821px #fff,
    271px 740px #fff,
    1505px 637px #fff,
    2519px 165px #fff,
    1820px 670px #fff,
    911px 1488px #fff,
    2408px 2376px #fff,
    1409px 988px #fff,
    1495px 382px #fff,
    1574px 1173px #fff,
    380px 270px #fff,
    136px 911px #fff,
    851px 2198px #fff,
    257px 1293px #fff,
    2488px 994px #fff,
    62px 1482px #fff,
    2550px 1419px #fff,
    640px 680px #fff,
    680px 1526px #fff,
    982px 1510px #fff,
    642px 544px #fff,
    947px 2285px #fff,
    1056px 409px #fff,
    220px 418px #fff,
    1564px 2224px #fff,
    1617px 1913px #fff,
    382px 2371px #fff,
    2218px 970px #fff,
    1424px 423px #fff,
    1792px 2395px #fff,
    188px 1993px #fff,
    782px 2188px #fff,
    1786px 1011px #fff,
    2321px 1970px #fff,
    817px 947px #fff,
    1899px 2288px #fff,
    2515px 2108px #fff,
    1441px 359px #fff,
    1117px 572px #fff,
    400px 1081px #fff,
    339px 2151px #fff,
    636px 2481px #fff,
    790px 434px #fff,
    2151px 475px #fff,
    1553px 293px #fff,
    2509px 1460px #fff,
    165px 1928px #fff,
    2103px 1090px #fff,
    1134px 1363px #fff,
    1217px 1160px #fff,
    515px 2490px #fff,
    1424px 571px #fff,
    2448px 947px #fff,
    2305px 131px #fff,
    1019px 1125px #fff,
    1412px 1023px #fff,
    971px 1075px #fff,
    2518px 1682px #fff,
    1574px 2150px #fff,
    613px 2488px #fff,
    1887px 1192px #fff,
    1431px 713px #fff,
    54px 1912px #fff,
    973px 842px #fff,
    1816px 2244px #fff,
    417px 1496px #fff,
    861px 1165px #fff,
    197px 2524px #fff,
    2215px 2327px #fff,
    1197px 848px #fff,
    752px 1968px #fff,
    1754px 1955px #fff,
    2362px 2409px #fff,
    2500px 2031px #fff,
    1379px 904px #fff,
    1003px 296px #fff,
    1002px 73px #fff,
    991px 521px #fff,
    497px 519px #fff,
    2557px 2006px #fff,
    1778px 205px #fff,
    284px 1721px #fff,
    2526px 1496px #fff,
    2113px 395px #fff,
    567px 1910px #fff,
    1824px 252px #fff,
    534px 754px #fff,
    1198px 39px #fff,
    2098px 1593px #fff,
    487px 2298px #fff,
    374px 2343px #fff,
    1911px 1171px #fff,
    1747px 759px #fff,
    1896px 2538px #fff,
    1996px 1833px #fff,
    2186px 953px #fff,
    206px 1906px #fff,
    322px 2488px #fff,
    1726px 409px #fff,
    194px 1147px #fff,
    2027px 991px #fff,
    574px 1511px #fff,
    25px 2369px #fff,
    1095px 1576px #fff,
    266px 1230px #fff,
    2312px 2033px #fff,
    128px 595px #fff,
    191px 413px #fff,
    1405px 685px #fff,
    420px 217px #fff,
    2514px 549px #fff,
    2344px 26px #fff,
    424px 41px #fff,
    1661px 403px #fff,
    509px 734px #fff,
    53px 1923px #fff,
    150px 487px #fff,
    16px 795px #fff,
    1342px 2330px #fff,
    1993px 2236px #fff,
    510px 1291px #fff,
    385px 180px #fff,
    1048px 1963px #fff,
    1901px 2475px #fff,
    1805px 2254px #fff,
    134px 986px #fff,
    10px 1803px #fff,
    861px 1057px #fff,
    443px 391px #fff,
    1620px 945px #fff,
    392px 1443px #fff,
    442px 1783px #fff,
    1500px 1747px #fff,
    1530px 1528px #fff,
    2246px 1821px #fff,
    2158px 974px #fff,
    509px 1381px #fff,
    136px 2137px #fff,
    1983px 2101px #fff,
    306px 1083px #fff,
    1965px 1659px #fff,
    1689px 1974px #fff,
    156px 1372px #fff,
    114px 1647px #fff,
    1935px 1754px #fff,
    277px 1796px #fff,
    1991px 1266px #fff,
    259px 2316px #fff,
    211px 2541px #fff,
    2472px 427px #fff,
    415px 1632px #fff,
    2513px 983px #fff,
    811px 1248px #fff,
    2547px 1543px #fff,
    2075px 680px #fff,
    1871px 734px #fff,
    2168px 540px #fff,
    2095px 159px #fff,
    1670px 2061px #fff,
    1522px 1789px #fff,
    281px 1495px #fff,
    339px 297px #fff,
    346px 1285px #fff,
    347px 1445px #fff,
    2443px 1556px #fff,
    1515px 148px #fff,
    1253px 632px #fff,
    1657px 2463px #fff,
    1588px 323px #fff,
    1460px 68px #fff,
    2098px 1606px #fff,
    922px 1311px #fff,
    369px 1275px #fff,
    1550px 898px #fff,
    2360px 331px #fff,
    631px 72px #fff,
    2455px 1942px #fff,
    205px 623px #fff,
    949px 1623px #fff,
    941px 2148px #fff,
    1782px 1842px #fff,
    630px 2223px #fff,
    574px 2534px #fff,
    937px 771px #fff,
    1572px 565px #fff,
    2245px 2315px #fff,
    114px 1288px #fff,
    1858px 2159px #fff,
    636px 27px #fff,
    691px 1881px #fff,
    1286px 1294px #fff,
    2164px 1552px #fff,
    20px 2087px #fff,
    474px 115px #fff,
    725px 2218px #fff,
    1715px 2377px #fff,
    12px 2540px #fff,
    2278px 1852px #fff,
    1110px 2131px #fff,
    714px 1617px #fff,
    877px 1426px #fff,
    1379px 37px #fff,
    830px 644px #fff,
    1027px 422px #fff,
    1482px 2545px #fff,
    690px 1266px #fff,
    2284px 2301px #fff,
    1580px 1496px #fff,
    1360px 1426px #fff,
    816px 324px #fff,
    1782px 196px #fff,
    1254px 2332px #fff,
    374px 1842px #fff,
    1188px 1697px #fff,
    1809px 1267px #fff,
    1432px 619px #fff,
    799px 2053px #fff,
    1564px 2258px #fff,
    1510px 1639px #fff,
    2370px 569px #fff,
    1396px 1676px #fff,
    1313px 46px #fff,
    1806px 1895px #fff,
    1972px 2195px #fff,
    1534px 961px #fff,
    1735px 730px #fff,
    1607px 999px #fff,
    545px 290px #fff,
    818px 360px #fff,
    1143px 915px #fff,
    2393px 796px #fff,
    206px 1828px #fff,
    2204px 1458px #fff,
    1410px 1410px #fff,
    1662px 418px #fff,
    2457px 1083px #fff,
    199px 1940px #fff,
    1100px 1839px #fff,
    1735px 366px #fff,
    514px 900px #fff,
    1699px 1925px #fff,
    320px 1315px #fff,
    148px 2004px #fff,
    679px 673px #fff,
    889px 2372px #fff,
    1434px 2034px #fff,
    1967px 1377px #fff,
    1883px 341px #fff,
    155px 1646px #fff,
    165px 1278px #fff,
    467px 1776px #fff,
    67px 2198px #fff,
    704px 1188px #fff,
    2459px 1289px #fff,
    1370px 243px #fff,
    2504px 103px #fff,
    155px 364px #fff,
    736px 2179px #fff,
    1309px 1934px #fff,
    1188px 2128px #fff,
    1159px 1625px #fff,
    1644px 531px #fff,
    1390px 1351px #fff,
    2421px 996px #fff,
    1357px 186px #fff,
    129px 1066px #fff,
    2001px 360px #fff,
    1998px 1811px #fff,
    863px 773px #fff,
    2420px 663px #fff,
    2182px 2254px #fff,
    124px 619px #fff,
    712px 1168px #fff,
    1378px 2380px #fff,
    2272px 1119px #fff,
    1280px 790px #fff,
    994px 2279px #fff,
    1234px 510px #fff,
    811px 1964px #fff,
    1415px 552px #fff,
    2166px 1101px #fff,
    1187px 2127px #fff,
    450px 1163px #fff,
    1998px 810px #fff,
    514px 640px #fff,
    2042px 107px #fff,
    1017px 2308px #fff,
    2106px 77px #fff,
    2357px 605px #fff,
    1099px 2213px #fff,
    365px 2155px #fff,
    752px 314px #fff,
    2205px 1105px #fff,
    1442px 1456px #fff,
    520px 1336px #fff,
    2114px 1493px #fff,
    2302px 917px #fff,
    2464px 2547px #fff,
    1629px 713px #fff,
    2245px 1111px #fff,
    688px 1895px #fff,
    2400px 2281px #fff,
    748px 1280px #fff,
    1055px 212px #fff,
    450px 1778px #fff,
    2526px 715px #fff,
    1581px 913px #fff,
    1292px 1958px #fff,
    1924px 1928px #fff,
    269px 1011px #fff,
    616px 688px #fff,
    44px 2029px #fff,
    439px 2056px #fff,
    2538px 2102px #fff,
    2263px 589px #fff,
    816px 7px #fff,
    651px 1525px #fff,
    1910px 787px #fff,
    2038px 296px #fff,
    93px 1230px #fff,
    1678px 1656px #fff,
    1912px 1349px #fff,
    1108px 1797px #fff,
    93px 531px #fff,
    496px 1046px #fff,
    1119px 2456px #fff,
    1908px 2243px #fff,
    2516px 1800px #fff,
    1689px 2215px #fff,
    945px 1535px #fff,
    709px 2182px #fff,
    2348px 83px #fff,
    2286px 2524px #fff,
    1358px 1550px #fff,
    2433px 2473px #fff,
    408px 1267px #fff,
    1216px 277px #fff,
    903px 1108px #fff,
    1810px 402px #fff,
    1737px 481px #fff,
    2324px 781px #fff,
    1998px 1453px #fff,
    1340px 1296px #fff,
    558px 1893px #fff,
    1096px 1070px #fff,
    773px 1523px #fff,
    943px 2222px #fff,
    1868px 2410px #fff,
    1577px 1896px #fff,
    1487px 820px #fff,
    2456px 570px #fff,
    2314px 2375px #fff,
    2186px 530px #fff,
    1344px 75px #fff,
    821px 2117px #fff,
    1458px 89px #fff,
    1237px 581px #fff,
    514px 1556px #fff,
    2526px 310px #fff,
    1606px 465px #fff,
    2337px 31px #fff,
    249px 1026px #fff,
    493px 1139px #fff,
    1652px 1851px #fff,
    804px 87px #fff,
    671px 2555px #fff,
    1125px 160px #fff,
    320px 84px #fff,
    2310px 1241px #fff,
    781px 2435px #fff,
    429px 210px #fff,
    1042px 233px #fff,
    1698px 1388px #fff,
    2176px 1563px #fff,
    1390px 2008px #fff,
    2310px 341px #fff,
    736px 1728px #fff,
    1863px 1987px #fff,
    226px 86px #fff,
    2313px 1725px #fff,
    1941px 1919px #fff,
    1200px 116px #fff,
    1076px 851px #fff,
    1841px 273px #fff,
    2447px 550px #fff,
    1567px 2264px #fff,
    2156px 260px #fff,
    850px 2121px #fff,
    941px 938px #fff,
    2009px 2404px #fff,
    1340px 1424px #fff,
    1372px 1086px #fff,
    1635px 60px #fff,
    92px 2407px #fff,
    2029px 366px #fff,
    2296px 254px #fff,
    642px 695px #fff,
    807px 1122px #fff,
    1018px 1983px #fff,
    1696px 1268px #fff,
    1267px 424px #fff,
    1807px 1244px #fff,
    1840px 992px #fff,
    1717px 1894px #fff,
    2500px 2092px #fff,
    1062px 453px #fff,
    260px 2496px #fff,
    1750px 854px #fff,
    140px 2385px #fff,
    1004px 1799px #fff,
    1377px 2183px #fff,
    1267px 1238px #fff,
    1476px 161px #fff,
    106px 1144px #fff,
    259px 1041px #fff,
    743px 615px #fff,
    580px 1279px #fff,
    441px 1633px #fff,
    667px 1499px #fff,
    43px 1897px #fff,
    1338px 1296px #fff,
    990px 2101px #fff,
    285px 1420px #fff,
    146px 680px #fff,
    1600px 1820px #fff,
    196px 2325px #fff,
    1091px 839px #fff,
    2080px 1839px #fff,
    1354px 1359px #fff,
    582px 206px #fff,
    1023px 2127px #fff,
    733px 2149px #fff,
    913px 2403px #fff,
    226px 166px #fff,
    1863px 449px #fff,
    219px 197px #fff,
    1619px 1406px #fff,
    1916px 1024px #fff,
    1853px 2351px #fff,
    473px 68px #fff,
    1315px 874px #fff,
    2278px 839px #fff,
    10px 2124px #fff,
    1450px 868px #fff,
    2461px 1551px #fff,
    968px 1329px #fff,
    188px 2430px #fff,
    1295px 1441px #fff,
    2516px 1432px #fff,
    521px 1215px #fff,
    413px 1303px #fff,
    961px 1928px #fff,
    409px 534px #fff,
    508px 1520px #fff,
    567px 419px #fff,
    920px 514px #fff,
    237px 1414px #fff,
    1087px 989px #fff,
    1906px 1646px #fff,
    1264px 1723px #fff,
    984px 1650px #fff,
    2130px 90px #fff,
    230px 274px #fff,
    826px 503px #fff,
    1127px 2392px #fff,
    244px 755px #fff,
    1083px 848px #fff,
    513px 1909px #fff,
    1049px 2428px #fff,
    452px 908px #fff,
    124px 1258px #fff,
    716px 2010px #fff,
    531px 2121px #fff,
    848px 1451px #fff,
    208px 1315px #fff,
    940px 1494px #fff,
    581px 2016px #fff,
    1243px 295px #fff,
    338px 2175px #fff,
    1135px 160px #fff,
    1452px 1833px #fff,
    244px 494px #fff,
    219px 2374px #fff,
    2450px 2117px #fff,
    187px 683px #fff,
    306px 299px #fff,
    1796px 818px #fff,
    427px 1336px #fff,
    534px 2025px #fff,
    703px 2196px #fff,
    1767px 964px #fff,
    1456px 2305px #fff,
    35px 1367px #fff,
    1958px 106px #fff,
    1185px 865px #fff,
    2533px 1700px #fff,
    1918px 2043px #fff,
    1801px 1433px #fff,
    1458px 2552px #fff,
    2079px 775px #fff,
    2271px 291px #fff,
    93px 1057px #fff,
    427px 1387px #fff,
    783px 1323px #fff,
    1216px 1472px #fff,
    2220px 1066px #fff,
    551px 1520px #fff,
    2038px 984px #fff,
    1701px 714px #fff,
    353px 2082px #fff,
    1812px 771px #fff,
    1067px 92px #fff,
    756px 480px #fff,
    2446px 1065px #fff,
    2202px 930px #fff,
    854px 987px #fff,
    1803px 2134px #fff,
    285px 2210px #fff,
    381px 749px #fff,
    2025px 625px #fff,
    772px 2232px #fff,
    799px 1326px #fff,
    1492px 1416px #fff,
    4px 1070px #fff,
    443px 1663px #fff,
    373px 1190px #fff,
    422px 497px #fff,
    662px 1784px #fff,
    1460px 1155px #fff,
    980px 2374px #fff,
    803px 619px #fff,
    2135px 1270px #fff,
    407px 1787px #fff,
    2305px 1252px #fff,
    1584px 2414px #fff,
    2304px 17px #fff,
    1764px 1725px #fff,
    190px 514px #fff,
    1360px 149px #fff,
    2369px 1927px #fff,
    540px 2066px #fff,
    1759px 327px #fff,
    2421px 2154px #fff,
    61px 1831px #fff,
    619px 1869px #fff,
    846px 332px #fff,
    899px 205px #fff,
    1369px 846px #fff,
    440px 1748px #fff,
    413px 2265px #fff,
    2510px 1212px #fff,
    2159px 2485px #fff,
    1802px 1665px #fff,
    2265px 236px #fff,
    1261px 2275px #fff,
    2263px 1062px #fff,
    375px 765px #fff,
    2112px 1256px #fff,
    1183px 1621px #fff,
    2157px 278px #fff,
    1617px 575px #fff,
    1907px 2128px #fff,
    273px 1598px #fff,
    2311px 1353px #fff,
    2421px 2475px #fff,
    189px 1319px #fff,
    1256px 984px #fff,
    1089px 2558px #fff,
    324px 1857px #fff,
    645px 230px #fff,
    2482px 990px #fff,
    1037px 1715px #fff,
    399px 1363px #fff,
    190px 1766px #fff,
    2198px 1395px #fff,
    1809px 2189px #fff,
    2348px 2009px #fff,
    939px 2370px #fff,
    999px 1711px #fff,
    996px 551px #fff,
    234px 1942px #fff,
    467px 2270px #fff,
    311px 990px #fff,
    1327px 258px #fff,
    2513px 629px #fff,
    2092px 971px #fff,
    496px 1056px #fff,
    1080px 2049px #fff,
    1485px 1120px #fff,
    1230px 2174px #fff,
    2521px 555px #fff,
    1183px 1630px #fff,
    507px 238px #fff,
    169px 2249px #fff,
    3px 2232px #fff,
    109px 318px #fff,
    1665px 1091px #fff,
    2114px 533px #fff,
    156px 2325px #fff,
    1390px 1123px #fff,
    1147px 2497px #fff,
    1725px 1102px #fff,
    1650px 1388px #fff,
    1325px 1467px #fff,
    2379px 1305px #fff,
    516px 2386px #fff,
    788px 2269px #fff,
    1589px 694px #fff,
    494px 2271px #fff,
    1996px 2371px #fff,
    226px 1647px #fff,
    35px 2234px #fff,
    1437px 881px #fff,
    2515px 912px #fff;
}

.stars2 {
  z-index: 10;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: transparent;
  opacity: 0.25;
  box-shadow:
    2256px 1775px #fff,
    2293px 679px #fff,
    758px 1384px #fff,
    694px 2039px #fff,
    935px 1369px #fff,
    932px 953px #fff,
    657px 882px #fff,
    638px 1855px #fff,
    2085px 2268px #fff,
    123px 463px #fff,
    2109px 1608px #fff,
    2120px 1355px #fff,
    2158px 83px #fff,
    2453px 2552px #fff,
    1547px 1562px #fff,
    1638px 2321px #fff,
    465px 1940px #fff,
    1629px 2384px #fff,
    668px 449px #fff,
    296px 278px #fff,
    1727px 59px #fff,
    2344px 2102px #fff,
    68px 678px #fff,
    1775px 549px #fff,
    653px 1571px #fff,
    1548px 981px #fff,
    1435px 1348px #fff,
    239px 738px #fff,
    1576px 1391px #fff,
    1035px 426px #fff,
    213px 774px #fff,
    416px 1522px #fff,
    1322px 463px #fff,
    874px 420px #fff,
    2483px 2269px #fff,
    291px 748px #fff,
    1587px 102px #fff,
    800px 2289px #fff,
    1798px 606px #fff,
    2135px 2250px #fff,
    175px 459px #fff,
    2342px 1370px #fff,
    863px 1387px #fff,
    405px 2031px #fff,
    541px 1698px #fff,
    599px 720px #fff,
    2390px 2057px #fff,
    110px 483px #fff,
    1930px 2044px #fff,
    938px 2238px #fff,
    1495px 2095px #fff,
    35px 1270px #fff,
    2247px 2407px #fff,
    1988px 1051px #fff,
    1289px 141px #fff,
    180px 1061px #fff,
    2055px 2246px #fff,
    1201px 851px #fff,
    1863px 567px #fff,
    6px 77px #fff,
    875px 766px #fff,
    837px 1010px #fff,
    35px 86px #fff,
    963px 765px #fff,
    1204px 1751px #fff,
    224px 712px #fff,
    1685px 1602px #fff,
    1078px 715px #fff,
    1707px 1256px #fff,
    429px 411px #fff,
    662px 2235px #fff,
    1985px 1951px #fff,
    1606px 1280px #fff,
    2123px 1123px #fff,
    2427px 620px #fff,
    701px 518px #fff,
    1870px 587px #fff,
    790px 2346px #fff,
    1367px 1376px #fff,
    1755px 954px #fff,
    2118px 2492px #fff,
    1087px 1951px #fff,
    569px 678px #fff,
    2163px 1271px #fff,
    2508px 759px #fff,
    2142px 758px #fff,
    1358px 2256px #fff,
    2141px 65px #fff,
    153px 381px #fff,
    1144px 1598px #fff,
    1912px 531px #fff,
    906px 2413px #fff,
    1941px 80px #fff,
    798px 1831px #fff,
    432px 611px #fff,
    1938px 2545px #fff,
    1155px 2053px #fff,
    1439px 973px #fff,
    1835px 1895px #fff,
    2148px 687px #fff,
    1786px 939px #fff,
    436px 636px #fff,
    610px 70px #fff,
    2348px 2379px #fff,
    2144px 207px #fff,
    1454px 2172px #fff,
    2412px 534px #fff,
    174px 78px #fff,
    295px 1070px #fff,
    1080px 2496px #fff,
    1940px 2089px #fff,
    1719px 2083px #fff,
    1074px 1644px #fff,
    524px 1768px #fff,
    1714px 222px #fff,
    2209px 13px #fff,
    902px 116px #fff,
    694px 108px #fff,
    2181px 1925px #fff,
    1373px 446px #fff,
    160px 1809px #fff,
    2433px 1254px #fff,
    1189px 2322px #fff,
    696px 2238px #fff,
    1915px 1986px #fff,
    1301px 794px #fff,
    782px 1047px #fff,
    718px 972px #fff,
    1335px 450px #fff,
    2326px 2208px #fff,
    188px 255px #fff,
    488px 489px #fff,
    944px 396px #fff,
    1905px 1592px #fff,
    1209px 374px #fff,
    2237px 1434px #fff,
    1735px 774px #fff,
    555px 2424px #fff,
    207px 686px #fff,
    1508px 2492px #fff,
    422px 1768px #fff,
    1688px 554px #fff,
    796px 8px #fff,
    1883px 2519px #fff,
    1068px 2440px #fff,
    3px 682px #fff,
    2109px 252px #fff,
    348px 1890px #fff,
    233px 460px #fff,
    2457px 2380px #fff,
    194px 2451px #fff,
    1237px 1569px #fff,
    2199px 335px #fff,
    1710px 1234px #fff,
    2520px 638px #fff,
    2332px 1759px #fff,
    1666px 471px #fff,
    2018px 574px #fff,
    1849px 2001px #fff,
    1911px 2242px #fff,
    1752px 779px #fff,
    2059px 1955px #fff,
    485px 1661px #fff,
    936px 1015px #fff,
    2521px 2170px #fff,
    742px 2348px #fff,
    2238px 2201px #fff,
    1817px 765px #fff,
    466px 1565px #fff,
    365px 1636px #fff,
    2319px 469px #fff,
    88px 2055px #fff,
    1376px 92px #fff,
    1488px 85px #fff,
    2389px 334px #fff,
    449px 875px #fff,
    1880px 1931px #fff,
    1532px 1913px #fff,
    737px 543px #fff,
    1640px 734px #fff,
    1638px 1185px #fff,
    269px 1919px #fff,
    1274px 1703px #fff,
    2322px 1543px #fff,
    2459px 2365px #fff,
    1699px 1975px #fff,
    1136px 2237px #fff,
    450px 2256px #fff,
    1128px 2275px #fff,
    1774px 36px #fff,
    662px 1829px #fff,
    1249px 339px #fff,
    1819px 690px #fff,
    1540px 786px #fff,
    2377px 474px #fff,
    323px 1572px #fff,
    786px 2530px #fff,
    1064px 2245px #fff,
    2255px 361px #fff,
    1607px 1495px #fff;
  animation: animStar 1875s linear infinite;
}
.stars2:after {
  content: " ";
  top: -600px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  position: absolute;
  background: transparent;
  box-shadow:
    1016px 330px #fff,
    2103px 973px #fff,
    825px 1799px #fff,
    629px 2338px #fff,
    1516px 81px #fff,
    700px 1936px #fff,
    1551px 2024px #fff,
    351px 2067px #fff,
    452px 1123px #fff,
    1478px 1454px #fff,
    2074px 1225px #fff,
    64px 1090px #fff,
    1781px 2392px #fff,
    2394px 978px #fff,
    922px 2425px #fff,
    1221px 1899px #fff,
    2383px 267px #fff,
    2316px 196px #fff,
    978px 983px #fff,
    2330px 2361px #fff,
    1009px 2024px #fff,
    2499px 2211px #fff,
    777px 1637px #fff,
    1500px 493px #fff,
    338px 1827px #fff,
    2506px 1248px #fff,
    2128px 1332px #fff,
    2470px 488px #fff,
    2409px 236px #fff,
    1126px 2028px #fff,
    1142px 299px #fff,
    422px 2557px #fff,
    1481px 2122px #fff,
    116px 1791px #fff,
    1583px 1149px #fff,
    2208px 259px #fff,
    718px 198px #fff,
    919px 1162px #fff,
    921px 2167px #fff,
    2449px 220px #fff,
    1037px 1051px #fff,
    1384px 2531px #fff,
    1755px 446px #fff,
    2190px 2068px #fff,
    709px 1022px #fff,
    1159px 1716px #fff,
    1895px 1773px #fff,
    433px 2184px #fff,
    1609px 1061px #fff,
    2220px 2498px #fff,
    1450px 2292px #fff,
    826px 544px #fff,
    2280px 1836px #fff,
    2454px 1732px #fff,
    1232px 2388px #fff,
    1833px 1813px #fff,
    284px 1227px #fff,
    2258px 1568px #fff,
    518px 272px #fff,
    1535px 1836px #fff,
    2429px 2448px #fff,
    1755px 2413px #fff,
    817px 2073px #fff,
    53px 1519px #fff,
    47px 2149px #fff,
    45px 1825px #fff,
    292px 2330px #fff,
    857px 640px #fff,
    1140px 1024px #fff,
    1959px 971px #fff,
    1072px 458px #fff,
    1133px 1536px #fff,
    2424px 2493px #fff,
    1877px 826px #fff,
    475px 704px #fff,
    1290px 1140px #fff,
    1191px 415px #fff,
    2237px 625px #fff,
    1965px 215px #fff,
    1795px 1960px #fff,
    2238px 1690px #fff,
    1005px 1029px #fff,
    1746px 1455px #fff,
    2138px 1767px #fff,
    2319px 2480px #fff,
    406px 1808px #fff,
    36px 871px #fff,
    2422px 296px #fff,
    734px 1423px #fff,
    793px 2060px #fff,
    213px 1884px #fff,
    1178px 1823px #fff,
    1063px 234px #fff,
    268px 1763px #fff,
    1678px 279px #fff,
    1261px 252px #fff,
    1771px 422px #fff,
    744px 725px #fff,
    1690px 1259px #fff,
    1081px 430px #fff,
    1689px 1001px #fff,
    307px 2449px #fff,
    1689px 1180px #fff,
    690px 664px #fff,
    1878px 403px #fff,
    445px 1345px #fff,
    1495px 1035px #fff,
    913px 1508px #fff,
    1374px 1158px #fff,
    2295px 1439px #fff,
    81px 1350px #fff,
    1927px 2152px #fff,
    2198px 802px #fff,
    1495px 365px #fff,
    1284px 2215px #fff,
    1967px 2238px #fff,
    2415px 1139px #fff,
    2530px 1863px #fff,
    2119px 1115px #fff,
    1739px 111px #fff,
    243px 2183px #fff,
    2366px 2339px #fff,
    1522px 1120px #fff,
    259px 1708px #fff,
    1286px 1036px #fff,
    50px 65px #fff,
    2379px 382px #fff,
    409px 1027px #fff,
    307px 350px #fff,
    2435px 2481px #fff,
    1935px 555px #fff,
    2376px 1447px #fff,
    1967px 2257px #fff,
    1235px 1112px #fff,
    1897px 163px #fff,
    1270px 1746px #fff,
    541px 2099px #fff,
    1090px 2030px #fff,
    107px 1944px #fff,
    1261px 2542px #fff,
    895px 187px #fff,
    2059px 1874px #fff,
    2555px 423px #fff,
    2172px 1360px #fff,
    2221px 2183px #fff,
    1223px 1098px #fff,
    497px 947px #fff,
    676px 2470px #fff,
    942px 1608px #fff,
    1009px 331px #fff,
    569px 1553px #fff,
    1273px 392px #fff,
    1313px 2448px #fff,
    296px 272px #fff,
    2323px 45px #fff,
    125px 1297px #fff,
    173px 1846px #fff,
    23px 1047px #fff,
    420px 259px #fff,
    2383px 1319px #fff,
    1074px 2382px #fff,
    2297px 2003px #fff,
    625px 1672px #fff,
    2465px 2329px #fff,
    376px 988px #fff,
    1037px 1371px #fff,
    524px 442px #fff,
    672px 774px #fff,
    733px 1712px #fff,
    1305px 978px #fff,
    2197px 625px #fff,
    173px 2105px #fff,
    1505px 1343px #fff,
    806px 1665px #fff,
    2522px 2220px #fff,
    2021px 20px #fff,
    1420px 1964px #fff,
    1603px 568px #fff,
    1357px 1240px #fff,
    1811px 1353px #fff,
    391px 6px #fff,
    1240px 1629px #fff,
    1937px 214px #fff,
    1017px 1336px #fff,
    1298px 730px #fff,
    1231px 2481px #fff,
    1809px 363px #fff,
    349px 204px #fff,
    1216px 844px #fff,
    1767px 621px #fff,
    825px 659px #fff,
    556px 792px #fff,
    2010px 2070px #fff,
    1387px 181px #fff,
    1659px 873px #fff,
    1500px 2221px #fff,
    581px 1173px #fff,
    1671px 241px #fff,
    549px 2285px #fff,
    338px 103px #fff;
}

/* Animation Elements */
@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2560px) translateX(-2560px);
  }
}

@keyframes animShootingStar {
  from {
    transform: translateY(0px) translateX(0px) rotate(315deg);
    opacity: 1;
    height: 5px;
  }
  25% {
    height: 400px;
    opacity: 1;
  }
  50% {
    height: 800px;
    opacity: 1;
  }
  to {
    transform: translateY(2560px) translateX(2560px) rotate(315deg);
    opacity: 0;
    height: 800px;
  }
}
