/* Basic Grid System */
.grid {
  display: grid;
  gap: var(--grid-gap, 1rem);
}

/* Common Grid Patterns */
.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

/* Responsive Grid */
@media (max-width: 768px) {
  .grid-2,
  .grid-3 {
    grid-template-columns: 1fr;
  }
}

/* Grid Alignment */
.grid-center {
  place-items: center;
}

.grid-space-between {
  justify-content: space-between;
  align-items: center;
}
